import { RequestStatus } from 'constants/API';
import { RecruitmentsState } from './type';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import recruitmentsSage from './saga';

export const initialState: RecruitmentsState = {
  getJobListStatus: RequestStatus.IDLE,
  createJobStatus: RequestStatus.IDLE,
  updateJobStatus: RequestStatus.IDLE,
  updateJobShowStatus: RequestStatus.IDLE,
  getJobCategoriesStatus: RequestStatus.IDLE,
  deleteJobStatus: RequestStatus.IDLE,

  recCandidateState: RequestStatus.IDLE,
  updateReCandidateState: RequestStatus.IDLE,
  deleteReCandidateState: RequestStatus.IDLE,
  getSourceCVStatus: RequestStatus.IDLE,
  uploadCVsStatus: RequestStatus.IDLE,
  createCandidateStatus: RequestStatus.IDLE,
  updateCandidateStatus: RequestStatus.IDLE,

  getCandidateListStatus: RequestStatus.IDLE,

  jobCategories: null,
  listJob: null,
  listError: null,

  errorMess: null,
  recruitmentCandidate: null,
  sourceCV: [],
  uploadCVsState: null,
  languageProficiency: [],

  listCandidate: null,
};

const slice = createSlice({
  name: 'recruitments',
  initialState,
  reducers: {
    //job list
    getJobListRequest: (state, action: PayloadAction<any>) => {
      if (action) state.getJobListStatus = RequestStatus.REQUESTING;
    },
    getJobListSuccess: (state, action: PayloadAction<any>) => {
      if (action) {
        state.getJobListStatus = RequestStatus.SUCCESS;
        state.listJob = action.payload;
      }
    },
    getJobListFail: (state, action: PayloadAction<any>) => {
      state.getJobListStatus = RequestStatus.ERROR;
    },
    createJobRequest: (state, action: PayloadAction<any>) => {
      if (action) state.createJobStatus = RequestStatus.REQUESTING;
    },
    createJobSuccess: (state, action: PayloadAction<any>) => {
      if (action) state.createJobStatus = RequestStatus.SUCCESS;
    },
    createJobFail: (state, action: PayloadAction<any>) => {
      state.createJobStatus = RequestStatus.ERROR;
      if (action) {
        state.listError = action.payload;
      }
    },
    updateJobRequest: (state, action: PayloadAction<any>) => {
      if (action) state.updateJobStatus = RequestStatus.REQUESTING;
    },
    updateJobSuccess: (state, action: PayloadAction<any>) => {
      if (action) state.updateJobStatus = RequestStatus.SUCCESS;
    },
    updateJobFail: (state, action: PayloadAction<any>) => {
      state.updateJobStatus = RequestStatus.ERROR;
      if (action) {
        state.listError = action.payload;
      }
    },
    getJobCategoriesRequest: (state, action: PayloadAction<any>) => {
      if (action) state.getJobCategoriesStatus = RequestStatus.REQUESTING;
    },
    getJobCategoriesSuccess: (state, action: PayloadAction<any>) => {
      if (action) {
        state.getJobCategoriesStatus = RequestStatus.SUCCESS;
        state.jobCategories = action.payload;
      }
    },
    getJobCategoriesFail: (state, action: PayloadAction<any>) => {
      state.getJobCategoriesStatus = RequestStatus.ERROR;
    },
    deleteJobRequest: (state, action: PayloadAction<any>) => {
      if (action) state.deleteJobStatus = RequestStatus.REQUESTING;
    },
    deleteJobSuccess: (state, action: PayloadAction<any>) => {
      state.deleteJobStatus = RequestStatus.SUCCESS;
    },
    deleteJobFail: (state, action: PayloadAction<any>) => {
      state.deleteJobStatus = RequestStatus.ERROR;
    },
    updateJobStatusRequest: (state, action: PayloadAction<any>) => {
      if (action) state.updateJobShowStatus = RequestStatus.REQUESTING;
    },
    updateJobStatusSuccess: (state, action: PayloadAction<any>) => {
      if (action) state.updateJobShowStatus = RequestStatus.SUCCESS;
    },
    updateJobStatusFail: (state, action: PayloadAction<any>) => {
      state.updateJobShowStatus = RequestStatus.ERROR;
      if (action) {
        state.listError = action.payload;
      }
    },
    resetJobInfoRequestStatus: state => {
      state.getJobListStatus = RequestStatus.IDLE;
      state.createJobStatus = RequestStatus.IDLE;
      state.getJobCategoriesStatus = RequestStatus.IDLE;
      state.updateJobStatus = RequestStatus.IDLE;
      state.deleteJobStatus = RequestStatus.IDLE;
      state.updateJobShowStatus = RequestStatus.IDLE;
    },
    resetJobState: state => {
      state.jobCategories = null;
      state.listJob = null;
      state.listError = null;
    },

    //job detail
    reCandidateRequest: (state, action: PayloadAction<any>) => {
      if (action) state.recCandidateState = RequestStatus.REQUESTING;
    },
    reCandidateSuccess: (state, action: PayloadAction<any>) => {
      if (action) {
        state.recCandidateState = RequestStatus.SUCCESS;
        state.recruitmentCandidate = action.payload;
      }
    },
    reCandidateFail: (state, action: PayloadAction<any>) => {
      if (action) state.recCandidateState = RequestStatus.ERROR;
    },

    updateReCandidateRequest: (state, action: PayloadAction<any>) => {
      if (action) state.updateReCandidateState = RequestStatus.REQUESTING;
    },
    updateReCandidateSuccess: (state, action: PayloadAction<any>) => {
      if (action) state.updateReCandidateState = RequestStatus.SUCCESS;
    },
    updateReCandidateFalse: (state, action: PayloadAction<any>) => {
      if (action) state.updateReCandidateState = RequestStatus.ERROR;
    },

    deleteReCandidateRequest: (state, action: PayloadAction<any>) => {
      if (action) state.deleteReCandidateState = RequestStatus.REQUESTING;
    },
    deleteReCandidateSuccess: (state, action: PayloadAction<any>) => {
      if (action) state.deleteReCandidateState = RequestStatus.SUCCESS;
    },
    deleteReCandidateFalse: (state, action: PayloadAction<any>) => {
      if (action) state.deleteReCandidateState = RequestStatus.ERROR;
    },

    getSourceCVRequest: (state, action: PayloadAction<any>) => {
      if (action) state.getSourceCVStatus = RequestStatus.REQUESTING;
    },
    getSourceCVSuccess: (state, action: PayloadAction<any>) => {
      if (action) {
        state.getSourceCVStatus = RequestStatus.SUCCESS;
        state.sourceCV = action.payload;
      }
    },
    getSourceCVFalse: (state, action: PayloadAction<any>) => {
      if (action) state.getSourceCVStatus = RequestStatus.ERROR;
    },

    uploadCVsRequest: (state, action: PayloadAction<any>) => {
      if (action) state.uploadCVsStatus = RequestStatus.REQUESTING;
    },
    uploadCVsSuccess: (state, action: PayloadAction<any>) => {
      if (action) {
        state.uploadCVsStatus = RequestStatus.SUCCESS;
        state.uploadCVsState = action.payload;
      }
    },
    uploadCVsFalse: (state, action: PayloadAction<any>) => {
      if (action) {
        state.uploadCVsStatus = RequestStatus.ERROR;
        state.errorMess = action.payload;
      }
    },

    createCandidateRequest: (state, action: PayloadAction<any>) => {
      if (action) state.createCandidateStatus = RequestStatus.REQUESTING;
    },
    createCandidateSuccess: (state, action: PayloadAction<any>) => {
      if (action) {
        state.createCandidateStatus = RequestStatus.SUCCESS;
        state.languageProficiency = action.payload;
      }
    },
    createCandidateFalse: (state, action: PayloadAction<any>) => {
      if (action) state.createCandidateStatus = RequestStatus.ERROR;
    },

    updateCandidateRequest: (state, action: PayloadAction<any>) => {
      if (action) state.updateCandidateStatus = RequestStatus.REQUESTING;
    },
    updateCandidateSuccess: (state, action: PayloadAction<any>) => {
      if (action) {
        state.updateCandidateStatus = RequestStatus.SUCCESS;
      }
    },
    updateCandidateFalse: (state, action: PayloadAction<any>) => {
      if (action) state.updateCandidateStatus = RequestStatus.ERROR;
    },

    resetJobDetailState: state => {
      state.updateReCandidateState = RequestStatus.IDLE;
      state.deleteReCandidateState = RequestStatus.IDLE;
      state.uploadCVsStatus = RequestStatus.IDLE;
      state.createCandidateStatus = RequestStatus.IDLE;
      state.updateCandidateStatus = RequestStatus.IDLE;
    },
    resetReCandidateState: state => {
      state.recruitmentCandidate = null;
      state.sourceCV = [];
      state.languageProficiency = [];
      state.sourceCV = [];
    },

    //Candidate list
    getCandidateListRequest: (state, action: PayloadAction<any>) => {
      if (action) state.getCandidateListStatus = RequestStatus.REQUESTING;
    },
    getCandidateListSuccess: (state, action: PayloadAction<any>) => {
      if (action) {
        state.getCandidateListStatus = RequestStatus.SUCCESS;
        state.listCandidate = action.payload;
      }
    },
    getCandidateListFail: (state, action: PayloadAction<any>) => {
      state.getCandidateListStatus = RequestStatus.ERROR;
    },

    resetCandidateInfoRequestStatus: state => {
      state.getJobCategoriesStatus = RequestStatus.IDLE;
    },
    resetCandidatesStatus: state => {
      state.getCandidateListStatus = RequestStatus.IDLE;
      state.getJobCategoriesStatus = RequestStatus.IDLE;
      state.deleteReCandidateState = RequestStatus.IDLE;
    },
    resetCandiddatesState: state => {
      state.listCandidate = null;
      state.jobCategories = null;
    },
  },
});

export default slice.actions;

export const useRecruitmentSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: recruitmentsSage });
  return { actions: slice.actions };
};
