import React, { useEffect } from 'react';
// UI
import {
  Box,
  IconButton,
  Collapse,
  Typography,
  Paper,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Stack,
} from '@mui/material';
import styled from 'styled-components/macro';
import _ from 'lodash';

import {
  KeyboardArrowUp as KeyboardArrowUpIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from '@mui/icons-material';

import { HourTag } from 'app/components/HourTag';
import { TagComponent } from 'app/components/TagComponent';
import { APPROVED_TASK_STATUS, REQUESTING_TASK_STATUS } from 'constants/common';
import { ReactComponent as EditIcon } from 'assets/icons/button/edit.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/button/delete.svg';
import { ITimesheet } from '../slice/types';
import { parseNumber } from 'utils/helper';

interface ICollapseTableProps {
  totalHours: number;
  project: ITimesheet[];
  setSeletedTaskLogData: (value: ITimesheet | undefined) => void;
  setIsShowDeleteTaskDialog: (value: boolean) => void;
  setIsShowModalTaskLog: (value: boolean) => void;
  isShowProject: number[];
  setIsShowProject: (value: number[]) => void;
}

export function CollapseTable(props: ICollapseTableProps) {
  const {
    totalHours,
    project,
    setSeletedTaskLogData,
    setIsShowModalTaskLog,
    setIsShowDeleteTaskDialog,
    isShowProject,
    setIsShowProject,
  } = props;
  const [open, setOpen] = React.useState(
    isShowProject?.includes(_.head(project)?.project?.id),
  );

  useEffect(() => {
    setOpen(isShowProject?.includes(_.head(project)?.project?.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowProject]);

  return (
    <CollapseTableWrapper>
      <Paper elevation={0}>
        <Box
          display="flex"
          flexDirection="row"
          sx={{ cursor: 'pointer' }}
          style={{ padding: '10px 0 10px 5px' }}
          onClick={() => {
            setOpen(!open);
            if (open) {
              setIsShowProject(
                isShowProject.filter(
                  pro => pro !== _.head(project)?.project?.id,
                ),
              );
            } else {
              setIsShowProject([
                ...isShowProject,
                _.head(project)?.project?.id,
              ]);
            }
          }}
        >
          <IconButton
            aria-label="expand row"
            size="small"
            style={{ marginRight: '5px' }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="h6" gutterBottom component="span" mb={0}>
              {_.head(project)?.project?.name}
            </Typography>
            <Typography variant="h6" gutterBottom component="span">
              <HourTag
                data={{ backgroundColor: '#BDD8FF', label: `${totalHours}h` }}
              ></HourTag>
            </Typography>
          </Stack>
        </Box>
        <Collapse
          in={open}
          timeout="auto"
          unmountOnExit
          style={{ borderTop: '1px solid #D9D9D9' }}
        >
          <TableContainer style={{ paddingLeft: '23.5px' }}>
            <Table>
              <TableBody>
                {project?.map((task, idx) => {
                  return (
                    <TableRow key={`task_${idx}`}>
                      <TableCell sx={{ minWidth: 130 }}>
                        <TaskContent>{task.description}</TaskContent>
                      </TableCell>
                      <TableCell sx={{ minWidth: 70 }} width={180}>
                        <Stack spacing={1}>
                          {task?.tags?.map((v, k) => (
                            <TagComponent data={v} key={k} />
                          ))}
                        </Stack>
                      </TableCell>
                      <TableCell sx={{ minWidth: 70 }} width={180}>
                        <TaskContent>
                          {parseNumber(Number(task.hours))}h
                        </TaskContent>
                      </TableCell>
                      <TableCell sx={{ minWidth: 70 }} width={150}>
                        <Stack direction="row" spacing={1}>
                          <IconButtonCustom
                            disabled={
                              _.toNumber(task?.status) ===
                                APPROVED_TASK_STATUS ||
                              _.toNumber(task?.status) ===
                                REQUESTING_TASK_STATUS
                            }
                            onClick={() => {
                              setSeletedTaskLogData(task);
                              setIsShowModalTaskLog(true);
                            }}
                          >
                            <EditIcon />
                          </IconButtonCustom>
                          <IconButtonCustom
                            disabled={
                              _.toNumber(task?.status) ===
                                APPROVED_TASK_STATUS ||
                              _.toNumber(task?.status) ===
                                REQUESTING_TASK_STATUS
                            }
                            color="error"
                            onClick={() => {
                              setSeletedTaskLogData(task);
                              setIsShowDeleteTaskDialog(true);
                            }}
                          >
                            <DeleteIcon />
                          </IconButtonCustom>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Collapse>
      </Paper>
    </CollapseTableWrapper>
  );
}
const CollapseTableWrapper = styled(Box)`
  table {
    & tr:last-child td {
      border: none;
    }
  }
`;

const IconButtonCustom = styled(IconButton)`
  &.Mui-disabled {
    cursor: not-allowed !important;
    pointer-events: unset !important;
  }
`;

const TaskContent = styled(Box)`
  font-size: 14px;
`;

export default CollapseTable;
