import React, { useEffect, useState } from 'react';
// UI
import { Helmet } from 'react-helmet-async';

import {
  Box,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
} from '@mui/material';
import styled from 'styled-components/macro';

import { Wrapper, Content } from 'app/components/Wrapper';
import { Container } from 'app/components/Container';
import moment from 'moment';
import _ from 'lodash';

// Redux & Sagas
import { useDispatch, useSelector } from 'react-redux';
import { useGlobalSlice } from 'app/pages/GlobalContainer/slice';
import FilterBar from './components/FilterBar';
import { ProjectsCollapse } from './components/ProjectsCollapse';
import { useMemberSlice } from '../MemberPage/slice';
import { REPORT_TYPE } from 'constants/common';
import { selectMember } from '../MemberPage/slice/selectors';
import { RequestStatus } from 'constants/API';
import { useLocation } from 'react-router';
import { Loader } from 'app/components/Loader';
import { checkIsRequesting, parseNumber, roundDecimal } from 'utils/helper';
import queryString from 'query-string';
import { useTimesheetSlice } from '../TimesheetPage/slice';
import { selectTimesheet } from '../TimesheetPage/slice/selectors';
import { selectGlobal } from '../GlobalContainer/slice/selectors';
import roles from 'constants/roles';
import { useTranslation } from 'react-i18next';

export function TimesheetOverviewPage() {
  const dispatch = useDispatch();
  const [totalHours, setTotalHours] = useState<number>(0);
  const [taskLogs, setTaskLogs] = useState([]);
  const [taskHours, setTaskHours] = useState({});
  const [currentDate, setCurrentDate] = useState(moment());
  const [datesInMonth, setDatesInMonth] = useState<any>([]);
  const [colSpan, setColSpan] = useState(currentDate.daysInMonth());
  const { t } = useTranslation();

  const {
    userSessionData: { userRole },
  } = useSelector(selectGlobal);

  const isCustomer = userRole === roles.IS_CUSTOMER;
  const pageTitle = isCustomer ? t('Timesheet Report') : t('Summary');

  const location = useLocation();

  const { setBreadcumbs } = useGlobalSlice().actions;

  const { getAllProjectsStatus } = useSelector(selectTimesheet);
  const {
    actions: { getMemberWorkReportRequest, resetTimesheetOverviewMemberStatus },
  } = useMemberSlice();
  const {
    actions: {
      getAllProjectsRequest,
      getAllMembersRequest,
      resetTimesheetOverviewStatus,
    },
  } = useTimesheetSlice();

  const { memberTasks, getMemberWorkReportStatus, getMemberListStatus } =
    useSelector(selectMember);

  const isLoading = checkIsRequesting([
    getMemberWorkReportStatus,
    getAllProjectsStatus,
    getMemberListStatus,
  ]);

  const reloadData = () => {
    const params = queryString.parse(window.location.search);
    const newCurrentDate = params?.month ? moment(params?.month) : moment();
    setCurrentDate(newCurrentDate);
    const member = params?.member ? Number(params?.member) : [];
    const project = params?.project || '';
    const search = params?.search?.trim() || '';
    dispatch(
      getMemberWorkReportRequest({
        date_start: newCurrentDate
          .clone()
          .startOf('month')
          .format('yyyy-MM-DD'),
        date_end: newCurrentDate.clone().endOf('month').format('yyyy-MM-DD'),
        report_type: REPORT_TYPE.DAY,
        member: member,
        project,
        search,
        type: _.isEmpty(member) ? 'all' : '',
      }),
    );
    const month = newCurrentDate.format('yyyy-MM');
    dispatch(
      getAllProjectsRequest({
        month,
      }),
    );
    dispatch(
      getAllMembersRequest({
        month,
      }),
    );
  };

  const getDatesInMonth = () => {
    const daysInMonth = currentDate.daysInMonth();
    const startOfMonth = currentDate.clone().startOf('month');
    const dates = _.times(daysInMonth).map(day => {
      const tempDate = startOfMonth.clone().add(day, 'days');
      return {
        dateString: tempDate.format('YYYY-MM-DD'),
        dateNumber: day + 1,
      };
    });
    setDatesInMonth(dates);
  };

  useEffect(() => {
    getDatesInMonth();
    const breadScrumbs = isCustomer
      ? [{ title: t('Timesheet Report') }]
      : [{ title: t('Summary') }];
    dispatch(setBreadcumbs(breadScrumbs));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    reloadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  useEffect(() => {
    if (getMemberWorkReportStatus === RequestStatus.SUCCESS) {
      let projectLogs = {};
      let memberLogs = {};
      let dailyReportData: any = [];
      memberTasks?.forEach(memberTask => {
        const projectId = memberTask?.project?.id;
        const memberId = memberTask?.member?.id;
        const reportDate = memberTask?.date;
        const duration = memberTask?.duration;
        const projectIdExist = dailyReportData.findIndex(
          (project: any) => project.id === projectId,
        );
        if (projectIdExist === -1) {
          dailyReportData.push({
            id: projectId,
            name: memberTask?.project?.name,
            totalProjectLogWithDay: {},
            totalProjectLogWithMonth: 0,
            member: [
              {
                id: memberId,
                avatar: memberTask?.member?.avatar,
                full_name: memberTask?.member?.full_name,
              },
            ],
          });
        } else {
          let memberData = dailyReportData[projectIdExist]?.member;
          const memberIdExist = memberData?.findIndex(
            (member: any) => member.id === memberId,
          );
          if (memberIdExist === -1) {
            memberData?.push({
              id: memberId,
              avatar: memberTask?.member?.avatar,
              full_name: memberTask?.member?.full_name,
            });
          }
        }
        // get project logs
        const projectLogKey = projectId + '_' + reportDate;
        if (!projectLogs?.[projectLogKey]) {
          projectLogs[projectLogKey] = 0;
        }
        let old_value = projectLogs[projectLogKey];
        projectLogs[projectLogKey] = roundDecimal(old_value + duration);
        // get total project logs
        const projectTotalLogKey = projectId + '_total';
        if (!projectLogs?.[projectTotalLogKey]) {
          projectLogs[projectTotalLogKey] = 0;
        }
        old_value = projectLogs[projectTotalLogKey];
        projectLogs[projectTotalLogKey] = roundDecimal(old_value + duration);
        // get member logs
        const memberLogKey = projectId + '_' + memberId + '_' + reportDate;
        if (!memberLogs?.[memberLogKey]) {
          memberLogs[memberLogKey] = 0;
        }
        old_value = memberLogs[memberLogKey];
        memberLogs[memberLogKey] = roundDecimal(old_value + duration);
        // get total member logs
        const memberTotalLogKey = projectId + '_' + memberId + '_total';
        if (!memberLogs?.[memberTotalLogKey]) {
          memberLogs[memberTotalLogKey] = 0;
        }
        old_value = memberLogs[memberTotalLogKey];
        memberLogs[memberTotalLogKey] = roundDecimal(old_value + duration);
      });
      getDatesInMonth();
      const totalSum = Object.keys(projectLogs).reduce((sum, key) => {
        if (key.includes('_total')) {
          return sum + projectLogs[key];
        }
        return sum;
      }, 0);

      const sortedDailyReportData = dailyReportData
        .sort((a: any, b: any) => a.id - b.id)
        .map((item: any) => ({
          ...item,
          member: item.member.sort(
            (member1: any, member2: any) => member1.id - member2.id,
          ),
        }));
      setTotalHours(totalSum);
      setTaskLogs(sortedDailyReportData);
      setTaskHours({ projectLogs, memberLogs });
      setColSpan(currentDate.daysInMonth());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getMemberWorkReportStatus]);

  useEffect(
    () => () => {
      dispatch(resetTimesheetOverviewStatus());
      dispatch(resetTimesheetOverviewMemberStatus());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ['componentWillUnMount'],
  );

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content="Palette" />
      </Helmet>
      <Container>
        <Wrapper>
          <Content spacing={2}>
            <Loader open={isLoading} />
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Typography variant="h6" component="h6">
                {pageTitle}
              </Typography>
            </Box>

            <FilterBar isCustomer={isCustomer} />

            <TableContainerCustom>
              <Table size="small" aria-label="a dense table" stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell
                      rowSpan={2}
                      className="normal_thead"
                      sx={{ minWidth: 180 }}
                      width={300}
                    >
                      {t('Member')}
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={colSpan}
                      style={{ backgroundColor: '#437DFF', top: '0' }}
                    >
                      <MonthHeader>
                        {currentDate.format('MMMM YYYY')}
                      </MonthHeader>
                    </TableCell>
                    <TableCell
                      rowSpan={2}
                      align="center"
                      className="normal_thead"
                      width={60}
                    >
                      {t('Total')}
                      <Typography className="total_hours">
                        {parseNumber(totalHours)}h
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    {datesInMonth.map((v, k) => {
                      return (
                        <TableCell
                          align="center"
                          className="day_thead"
                          key={k}
                          style={{ top: '33.5px' }}
                        >
                          {v.dateNumber}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                {taskLogs.map((projectTasks: any) => {
                  return (
                    <ProjectsCollapse
                      key={projectTasks?.id}
                      datesInMonth={datesInMonth}
                      projectId={projectTasks?.id}
                      taskHours={taskHours}
                      projectTasks={projectTasks}
                    ></ProjectsCollapse>
                  );
                })}
                {_.isEmpty(taskLogs) && (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={colSpan + 2}>
                        {t('No data')}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainerCustom>
          </Content>
        </Wrapper>
      </Container>
    </>
  );
}

const TableContainerCustom = styled(TableContainer)`
  &.MuiTableContainer-root {
    max-height: calc(100vh - 190px);
    & table thead tr th {
      text-transform: none;
      white-space: nowrap;
    }
  }
  table {
    .no_wrap {
      white-space: nowrap;
    }
    .MuiTableCell-root {
      padding: 6px 10px;
      border: 1px solid #e9e9e9;
    }
    thead {
      tr th {
        background: #e6f1ff;
      }
      th {
        font-weight: 500 !important;
      }
    }
    tbody tr {
      background: #fff;
    }
    tbody.project_header {
      tr {
        background: #f5f5f5;
      }
      td {
        font-weight: 500;
        font-size: 9px;
      }
    }
    tbody.project_content {
      td {
        font-weight: 400;
        font-size: 9px;
      }
    }
    th.normal_thead {
      font-size: 14px;
    }
    th.day_thead {
      font-size: 10px;
    }
  }
  .total_hours {
    color: red;
    font-size: 16px;
    font-weight: 500;
  }
`;

const MonthHeader = styled(Box)`
  color: #fff;
  font-size: 14px;
`;
