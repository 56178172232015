import React, { useState, useEffect, useMemo } from 'react';

// UI
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Typography,
  Stack,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Pagination,
  Tooltip,
  TextField,
  InputAdornment,
  Chip,
  IconButton,
  Button,
} from '@mui/material';
import {
  Delete,
  RemoveRedEye,
  Search as SearchIcon,
  FilterAlt as FilterAltIcon,
} from '@mui/icons-material';
import { Loader } from 'app/components/Loader';

import { Container } from 'app/components/Container';
import { Wrapper, Content } from 'app/components/Wrapper';
import Path from 'config/clientPath';
import { useLocation } from 'react-router-dom';
// Redux & Sagas
import { useDispatch } from 'react-redux';
import { useGlobalSlice } from 'app/pages/GlobalContainer/slice';

// Library
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import _ from 'lodash';
import styled from 'styled-components';
import { checkIsRequesting, handleParamSearch } from 'utils/helper';

import { RequestStatus } from 'constants/API';
import { useTranslation } from 'react-i18next';
import { CANDIDATE_STATUS, CANDIDATE_STATUS_COLOR } from 'constants/common';
import { useForm } from 'react-hook-form';
import useRecruitment from '../RecruitmentsPage/useRecruitment';
import { Colors } from '../HomePage/Components/Variables';
import ConfirmDelete from 'app/components/ConfirmDelete';
import { ICandidate } from '../RecruitmentsPage/slice/type';
import { FilterModal } from './components/FilterModal';

export function CandidatesPage() {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(window.location.search);
  const { t } = useTranslation();
  const filterCandidatesForm = useForm();
  const { register, watch, reset } = filterCandidatesForm;
  const {
    getCandidateHistory,
    listCandidate,
    getCandidateListStatus,
    getJobCategoriesStatus,
    getJobCategories,
    deleteReCandidate,
    deleteReCandidateState,
    resetCandidateListStatus,
    getSkillsTechnial,
    getLanguages,
    jobCategories,
    language_metadata,
    skillsList,
    resetCandiddatesListState,
  } = useRecruitment();
  const { setBreadcumbs, setSuccessMessages } = useGlobalSlice().actions;

  const [dataItem, setDataItem] = useState<ICandidate | null>();
  const [showConfirm, setShowConfirm] = useState(false);
  const [isShowModal, setIsShowModal] = useState(false);

  const isLoading = checkIsRequesting([
    getCandidateListStatus,
    getJobCategoriesStatus,
    deleteReCandidateState,
  ]);

  const reloadData = () => {
    const params = new URLSearchParams(window.location.search);
    getCandidateHistory({
      status: params.get('status'),
      start_date: params.get('start_date'),
      end_date: params.get('end_date'),
      category: params.get('category'),
      skills: params.get('skills'),
      languages: params.get('languages'),
      search: params.get('search'),
      page: Math.abs(_.toInteger(params.get('page')) || 1),
    });
  };

  const countFilter = () => {
    const countFields = [
      'start_date',
      'end_date',
      'category',
      'status',
      'skills',
      'languages',
    ];
    const count = countFields.filter(key => params.get(key)).length;
    if (count > 0) {
      return '+' + count;
    }
    return '';
  };

  const handleChangePage = (
    event: React.ChangeEvent<unknown> | null,
    newPage: number,
  ) => {
    const params = queryString.parse(window.location.search);
    const qs = queryString.stringify({ ...params, page: +newPage });
    history.push(`${Path.CANDIDATES}?${qs}`);
  };

  useEffect(() => {
    dispatch(setBreadcumbs([{ title: t('Candidates') }]));
    getJobCategories();
    getSkillsTechnial();
    getLanguages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const candidatesArr = useMemo(
    () =>
      Object.keys(CANDIDATE_STATUS).map(key => ({
        key,
        name: CANDIDATE_STATUS[key],
      })),
    [],
  );

  useEffect(() => {
    reloadData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  useEffect(() => {
    reset({
      start_date: params.get('start_date'),
      end_date: params.get('end_date'),
      category: _.find(
        jobCategories,
        it => it.id.toString() === params.get('category'),
      ),
      status: _.filter(candidatesArr, item =>
        _.includes(params.get('status')?.split(','), item.key),
      ),
      skills: _.filter(skillsList, item =>
        _.includes(params.get('skills')?.split(','), item.id.toString()),
      ),
      languages: _.filter(language_metadata, item =>
        _.includes(params.get('languages')?.split(','), item.id.toString()),
      ),
      search: params.get('search'),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobCategories, skillsList, language_metadata, isShowModal]);

  useEffect(() => {
    if (deleteReCandidateState === RequestStatus.SUCCESS) {
      dispatch(setSuccessMessages([t('Job delete successful!')]));
      resetCandidateListStatus();
      reloadData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteReCandidateState]);

  useEffect(
    () => () => {
      resetCandidateListStatus();
      resetCandiddatesListState();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ['componentWillUnMount'],
  );

  return (
    <>
      <Helmet>
        <title>{t('Candidates')}</title>
        <meta name="description" content={t('Candidates List Management')} />
      </Helmet>
      <Container>
        <Wrapper>
          <Loader open={isLoading} />
          <Content spacing={2}>
            <FilterModal
              filterCandidatesForm={filterCandidatesForm}
              isShowModal={isShowModal}
              setIsShowModal={setIsShowModal}
            />
            <ConfirmDelete
              open={showConfirm}
              onClose={() => {
                setShowConfirm(false);
                setDataItem(null);
              }}
              onConfirm={() => {
                setShowConfirm(false);
                deleteReCandidate(dataItem);
              }}
              title="candidate"
              name={dataItem?.full_name}
            />

            <Typography variant="h6" component="h6">
              {t('Candidates')}
            </Typography>
            <Stack flexDirection="row" justifyContent="space-between">
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="end"
                alignItems="center"
              >
                <Button
                  variant="contained"
                  startIcon={<FilterAltIcon />}
                  color="inherit"
                  style={{ marginRight: '6px' }}
                  onClick={() => {
                    setIsShowModal(true);
                  }}
                >
                  {t('Filter')}
                </Button>
                {countFilter()}
              </Box>

              <TextField
                type="search"
                placeholder="Name"
                aria-label="Search"
                size="small"
                sx={{
                  background: Colors.white,
                  width: 200,
                }}
                onKeyUp={event => {
                  if (event.key === 'Enter') {
                    const dataSearch = {
                      start_date: params.get('start_date'),
                      end_date: params.get('end_date'),
                      category: params.get('category'),
                      skills: params.get('skills'),
                      languages: params.get('languages'),
                      page: 1,
                      search: watch('search'),
                    };
                    history.push(
                      `${Path.CANDIDATES}?${handleParamSearch(dataSearch)}`,
                    );
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                {...register('search')}
              />
            </Stack>

            <WrapTable>
              <TableContainer component={Paper}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ minWidth: 150 }}>
                        {t('Candidate Name')}
                      </TableCell>
                      <TableCell sx={{ minWidth: 60 }}>
                        {t('Job Name')}
                      </TableCell>
                      <TableCell sx={{ minWidth: 90 }}>{t('Skills')}</TableCell>
                      <TableCell sx={{ minWidth: 90 }}>
                        {t('Languages')}
                      </TableCell>
                      <TableCell sx={{ minWidth: 90 }}>
                        {t('Education')}
                      </TableCell>
                      <TableCell sx={{ minWidth: 90 }}>
                        {t('Applied Date')}
                      </TableCell>
                      <TableCell sx={{ minWidth: 90 }}>{t('Status')}</TableCell>
                      <TableCell
                        sx={{ minWidth: 120, textAlign: 'center' }}
                        width={120}
                      >
                        {t('Action')}
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {_.isEmpty(listCandidate?.data) ? (
                      <TableRow>
                        <TableCell colSpan={8}>{t('No data')}</TableCell>
                      </TableRow>
                    ) : (
                      listCandidate?.data.map((item, idx) => {
                        return (
                          <TableRow key={idx}>
                            <TableCell sx={{ minWidth: 120 }}>
                              <Typography
                                color={Colors.blueText}
                                fontSize="inherit"
                                onClick={() => {
                                  history.push(`candidates/${item.id}`);
                                }}
                                sx={{ cursor: 'pointer' }}
                              >
                                {item.full_name}
                              </Typography>
                            </TableCell>
                            <TableCell sx={{ minWidth: 120 }}>
                              {item.job.title}
                            </TableCell>
                            <TableCell sx={{ minWidth: 20 }}>
                              {item.skills &&
                                item?.skills?.map((v, k) => (
                                  <Typography fontSize="11px" key={k}>
                                    - {v.name}
                                  </Typography>
                                ))}
                            </TableCell>
                            <TableCell>
                              {item.language_proficiency &&
                                item?.language_proficiency.map((v, k) => (
                                  <Typography key={k}>{v.name}</Typography>
                                ))}
                            </TableCell>
                            <TableCell>
                              <Tooltip
                                title={item.education}
                                placement="bottom"
                              >
                                <TruncatedTypography>
                                  {item.education}
                                </TruncatedTypography>
                              </Tooltip>
                            </TableCell>
                            <TableCell>
                              {item.applied_date?.replaceAll('-', '/')}
                            </TableCell>
                            <TableCell>
                              <Chip
                                label={`${CANDIDATE_STATUS[item?.status]}`}
                                color={CANDIDATE_STATUS_COLOR[item?.status]}
                                variant="filled"
                              />
                            </TableCell>
                            <TableCell>
                              <IconButton
                                onClick={() => {
                                  history.push(
                                    `${Path.CANDIDATES}/${item.id}/`,
                                  );
                                }}
                              >
                                <RemoveRedEye />
                              </IconButton>
                              <IconButton
                                onClick={() => {
                                  setDataItem(item);
                                  setShowConfirm(true);
                                }}
                              >
                                <Delete />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </WrapTable>
            {!_.isEmpty(listCandidate?.data) && (
              <Stack spacing={2} alignItems={'flex-end'}>
                <Pagination
                  page={listCandidate?.current_page || 1}
                  color="primary"
                  count={listCandidate?.total_page || 0}
                  variant="outlined"
                  shape="rounded"
                  onChange={handleChangePage}
                />
              </Stack>
            )}
          </Content>
        </Wrapper>
      </Container>
    </>
  );
}

const WrapTable = styled(Box)`
  & .MuiTableContainer-root {
    max-height: calc(100vh - 230px);
  }
  & .MuiAvatarGroup-avatar {
    background-color: #ffd8bf;
    color: #fa541c;
  }
  & .MuiAvatar-root:last-child {
    margin-left: -8px !important;
  }
  .cursor_pointer {
    cursor: pointer;
  }
`;
const TruncatedTypography = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100px;
`;
