import React, { useEffect } from 'react';

// UI
import { Helmet } from 'react-helmet-async';
import {
  Tabs,
  Tab,
  Paper,
  Stack,
  IconButton,
  TextField,
  Typography,
  Box,
  Button,
} from '@mui/material';
import { LoadingButton, TabContext, TabPanel } from '@mui/lab';
import styled from 'styled-components/macro';

import { Container } from 'app/components/Container';
import { Wrapper, Content } from 'app/components/Wrapper';

import SkillsForm from './components/SkillsForm';
import BasicInfo from '../MemberPage/components/BasicInfo';
import { PageTitle } from 'app/components/PageTitle';
// Redux & Sagas
import { useDispatch, useSelector } from 'react-redux';
import { RequestStatus } from 'constants/API';
import { selectMember } from 'app/pages/MemberPage/slice/selectors';
import { useMemberSlice } from 'app/pages/MemberPage/slice';
import { IMemberLanguageSchema } from 'app/pages/MemberPage/slice/types';
import { useGlobalSlice } from 'app/pages/GlobalContainer/slice';
import { useProjectsSlice } from 'app/pages/ProjectPage/slice';

// Library
import _ from 'lodash';

import { FormProvider, useForm } from 'react-hook-form';
import { useParams, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';
import { convertDate } from 'utils/moment';
import Path from 'config/clientPath';
import MemberReport from 'app/components/MemberReport';
import { Loader } from 'app/components/Loader';
import { checkIsRequesting } from 'utils/helper';
import { selectGlobal } from '../GlobalContainer/slice/selectors';
import moment from 'moment';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { DatePicker } from '@mui/lab';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import SaveIcon from '@mui/icons-material/Save';
import Assignment from './components/Assignment';
import { selectForgotPassword } from '../ForgotPasswordPage/slice/selectors';

interface RouteParams {
  id: string;
}

export const STATUS_LIST = {
  '1': 'Active',
  '2': 'Deactive',
};

export function MemberEditPage() {
  const dispatch = useDispatch();
  const [month, setMonth] = React.useState<moment.Moment | null>(moment());
  const location = useLocation();
  const history = useHistory();
  const params = useParams<RouteParams>();

  const [tabIndex, setTabIndex] = React.useState('1');
  const { getProjectListRequest } = useProjectsSlice().actions;
  const { setBreadcumbs, setSuccessMessages, setUserSessionData } =
    useGlobalSlice().actions;
  const { userSessionData } = useSelector(selectGlobal);

  const {
    actions: {
      getMemberInfoRequest,
      getLanguageListRequest,
      getSkillListRequest,
      getMemberHistoryAssignmentRequest,
      getMemberCurrentAssignmentRequest,
      resetMemberInfoRequestStatus,
      updateMemberInfoRequest,
      getSkillManagementRequest,
      resetBasicInfoStatus,
      getDepartmentRequest,
      getProjectRoleListRequest,
      getLevelsRequest,
    },
  } = useMemberSlice();

  const {
    memberInfo,
    getMemberInfoStatus,
    getLanguageListStatus,
    getMemberCurrentAssignmentStatus,
    getMemberHistoryAssignmentStatus,
    getSkillListStatus,
    updateMemberInfoStatus,
    project_role_metadata,
    language_metadata,
    getMemberWorkReportStatus,
    getMemberEffortStatus,
    skillsList,
    deleteAssignmentInfoStatus,
  } = useSelector(selectMember);
  const { forgotPasswordStatus } = useSelector(selectForgotPassword);

  const memberForm = useForm();

  const isLoading = checkIsRequesting([
    getMemberInfoStatus,
    getMemberWorkReportStatus,
    getMemberEffortStatus,
    forgotPasswordStatus,
    getMemberCurrentAssignmentStatus,
    getMemberHistoryAssignmentStatus,
  ]);

  const handleChangeTabIndex = (
    event: React.SyntheticEvent,
    newValue: string,
  ) => {
    let paramsTab = queryString.parse(window.location.search);
    if (newValue) {
      paramsTab = { ...paramsTab, tab: newValue };
      setTabIndex(newValue);
      if (newValue === '4') {
        dispatch(getMemberInfoRequest(params.id));
      }
    }
    const qs = queryString.stringify(paramsTab);
    history.push(`${Path.MEMBERS}/edit/${params.id}?${qs}`);
  };

  useEffect(() => {
    if (params.id) {
      dispatch(
        setBreadcumbs([
          { title: 'Member', path: Path.MEMBERS },
          { title: 'Profile' },
        ]),
      );
      dispatch(getDepartmentRequest({}));
      dispatch(getProjectRoleListRequest({}));
      dispatch(getLevelsRequest({}));
      dispatch(getLanguageListRequest({}));
      dispatch(getSkillListRequest({}));
      dispatch(getSkillManagementRequest({}));
      dispatch(getProjectListRequest({ type: 'all', paging: false }));
      dispatch(getMemberInfoRequest(params.id));
      dispatch(getMemberHistoryAssignmentRequest(params.id));
      dispatch(getMemberCurrentAssignmentRequest(params.id));
    } else {
      dispatch(
        setBreadcumbs([
          { title: 'Member', path: Path.MEMBERS },
          { title: 'Create new member' },
        ]),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (deleteAssignmentInfoStatus === RequestStatus.SUCCESS) {
      dispatch(getMemberCurrentAssignmentRequest(params.id));
      dispatch(getMemberHistoryAssignmentRequest(params.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteAssignmentInfoStatus]);

  const updateNewMemberInfo = () => {
    if (memberInfo?.id === userSessionData.profile?.id) {
      const newRole = project_role_metadata?.find(
        v => v.id === memberInfo?.role,
      );
      let newSkills: any[] = [];
      if (memberInfo?.technical_skills?.length) {
        _.map(memberInfo?.technical_skills, v => {
          const foundSkill = skillsList?.find(item => item.id === v.skill);
          if (foundSkill) {
            newSkills = [...newSkills, { ...foundSkill }];
          }
        });
      }

      const newMemberInfo = {
        ...userSessionData,
        profile: {
          ...userSessionData.profile,
          avatar: memberInfo?.avatar || '',
          email: memberInfo?.email || '',
          full_name: memberInfo?.full_name || '',
          gender: _.toNumber(memberInfo?.gender) || 0,
          role: newRole?.name || '',
          skill: newSkills,
        },
      };
      dispatch(setUserSessionData(newMemberInfo));
    }
  };

  useEffect(() => {
    if (updateMemberInfoStatus === RequestStatus.SUCCESS) {
      updateNewMemberInfo();
      dispatch(setSuccessMessages(['Member update successful!']));
      if (tabIndex === '2') {
        dispatch(getMemberInfoRequest(params.id));
        memberForm.reset();
      }
      dispatch(resetBasicInfoStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateMemberInfoStatus]);

  useEffect(
    () => () => {
      dispatch(resetMemberInfoRequestStatus());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ['componentWillUnMount'],
  );

  React.useEffect(
    () => () => {
      memberForm.reset();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tabIndex],
  );

  useEffect(() => {
    if (
      getMemberInfoStatus === RequestStatus.SUCCESS &&
      getLanguageListStatus === RequestStatus.SUCCESS &&
      getSkillListStatus === RequestStatus.SUCCESS
    ) {
      dispatch(resetBasicInfoStatus());

      if (memberInfo) {
        const data = _.cloneDeep(memberInfo);

        if (!data.languages) {
          data.languages = [];
        }
        const languages: IMemberLanguageSchema[] = [];
        language_metadata?.forEach((it, idx) => {
          languages.push(
            _.find(data.languages, { language: it.id }) || {
              language: it.id,
              reading_level: 0,
              writing_level: 0,
              speaking_level: 0,
              listening_level: 0,
              certificate: '',
            },
          );
        });
        data.languages = languages;
        memberForm.reset(data);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getMemberInfoStatus, getLanguageListStatus, getSkillListStatus]);

  const handleSaveData = formData => {
    formData.birthday = convertDate(formData.birthday);
    formData.join_date = convertDate(formData.join_date);
    formData.resign_date = convertDate(formData.resign_date);

    formData.projects = formData.projects
      .map(it => {
        const d = {
          ...it,
          start_date: convertDate(it.start_date),
        };
        const end_date = convertDate(it.end_date);
        if (end_date) {
          d.end_date = end_date;
        } else {
          delete d.end_date;
        }
        return d;
      })
      .filter(o => o.project && o.role);

    formData.project_histories = formData.project_histories.filter(
      o => o.project_name !== '' && o.company_name !== '' && o.period !== '',
    );

    // normalize
    formData.technical_skills = _.uniq(
      formData.technical_skills.filter(
        o => _.toInteger(o.skill) !== 0 && _.toInteger(o.level) !== 0,
      ),
      'skill',
    );
    formData.management_skills = _.uniq(
      formData.management_skills.filter(
        o => _.toInteger(o.skill) !== 0 && _.toInteger(o.level) !== 0,
      ),
      'skill',
    );

    formData.languages = formData.languages.filter(
      o =>
        _.toInteger(o.reading_level) !== 0 &&
        _.toInteger(o.writing_level) !== 0 &&
        _.toInteger(o.speaking_level) !== 0 &&
        _.toInteger(o.listening_level) !== 0,
    );

    const formDataParams = { ...formData };
    // remove params of other form to avoid error when save data
    if (!formDataParams.first_name) {
      delete formDataParams.first_name;
    }
    if (!formDataParams.last_name) {
      delete formDataParams.last_name;
    }
    if (!formDataParams.role) {
      delete formDataParams.role;
    }

    const paramsSearch = new URLSearchParams(window.location.search);
    if (paramsSearch.get('tab') === '2') {
      delete formDataParams.avatar;
    }

    dispatch(
      updateMemberInfoRequest({
        objectId: params.id,
        formData: formDataParams,
      }),
    );
    memberForm.reset(formDataParams);
  };

  const handleResetData = () => {
    memberForm.reset();
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const newMemberTab = params.get('tab');
    setTabIndex(newMemberTab ? newMemberTab : '1');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  return (
    <>
      <Helmet>
        <title>Member Edit</title>
        <meta name="description" content="Customer Management" />
      </Helmet>
      <Container>
        <Wrapper
          subToolbar={
            <>
              <PageTitle>
                Member Profile
                {memberInfo?.full_name ? ` / ${memberInfo?.full_name}` : ''}
              </PageTitle>
              <Tabs onChange={handleChangeTabIndex} value={tabIndex}>
                <Tab label="Basic Info" value="1" />
                <Tab label="Skills" value="2" />
                <Tab label="Assignment" value="3" />
                <Tab label="Report" value="4" />
                {+tabIndex === 2 && (
                  <Stack
                    alignSelf="center"
                    width="100%"
                    pr={1}
                    direction="row"
                    justifyContent="flex-end"
                    spacing={2}
                  >
                    <Button color="error" onClick={handleResetData}>
                      Reset data
                    </Button>

                    <LoadingButton
                      type="submit"
                      variant="contained"
                      loadingPosition="start"
                      startIcon={<SaveIcon />}
                      onClick={memberForm.handleSubmit(handleSaveData)}
                      loading={
                        updateMemberInfoStatus === RequestStatus.REQUESTING
                      }
                    >
                      Save
                    </LoadingButton>
                  </Stack>
                )}
              </Tabs>
            </>
          }
        >
          <Loader open={isLoading} />
          <Content spacing={2}>
            <FormProvider {...memberForm}>
              <Paper
                sx={{ boxShadow: 'none' }}
                id="member-form"
                style={{ borderRadius: '12px' }}
              >
                <TabContext value={tabIndex}>
                  <ContentTab value="1" style={{ padding: '24px 100px' }}>
                    <BasicInfo type="EDIT_MEMBER" memberForm={memberForm} />
                  </ContentTab>
                  <ContentTab value="2">
                    <SkillsForm memberForm={memberForm} />
                  </ContentTab>
                  <ContentTab value="3">
                    <Typography variant="h6" component="h6">
                      Current
                    </Typography>
                    <Assignment
                      showHeader={true}
                      showMember={false}
                      showProject={true}
                      isRenderByKeyName={true}
                      isCurrentNotHistory={true}
                      memberInfo={memberInfo}
                    />
                    <Assignment
                      showHeader={true}
                      showMember={false}
                      showProject={true}
                      isRenderByKeyName={true}
                      isCurrentNotHistory={false}
                      memberInfo={memberInfo}
                    />
                  </ContentTab>
                  <ReportContentTab value="4">
                    <Stack
                      mb={2}
                      direction="row"
                      alignItems="center"
                      spacing={'5px'}
                    >
                      <CalendarIcon />
                      <Box>Month:</Box>
                      <WrapDateRange>
                        <IconButton>
                          <ChevronLeft
                            onClick={() => {
                              const currentMonth = month || moment();
                              setMonth(
                                currentMonth.clone().subtract(1, 'month'),
                              );
                            }}
                          />
                        </IconButton>
                        <DatePicker
                          inputFormat="YYYY/MM"
                          views={['month', 'year']}
                          value={month}
                          onChange={newValue => {
                            setMonth(newValue);
                          }}
                          renderInput={params => (
                            <TextField {...params} size="small" />
                          )}
                        />
                        <IconButton>
                          <ChevronRight
                            onClick={() => {
                              const currentMonth = month || moment();
                              setMonth(currentMonth.clone().add(1, 'month'));
                            }}
                          />
                        </IconButton>
                      </WrapDateRange>
                    </Stack>
                    <MemberReport
                      month={month}
                      type="viewer"
                      memberId={memberInfo?.id || ''}
                    />
                  </ReportContentTab>
                </TabContext>
              </Paper>
            </FormProvider>
          </Content>
        </Wrapper>
      </Container>
    </>
  );
}

const ReportContentTab = styled(TabPanel)`
  padding: 0 !important;
  background-color: #f0f2f5;
`;

const ContentTab = styled(TabPanel)`
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
`;

const WrapDateRange = styled(Box)`
  & .MuiOutlinedInput-root {
    width: 130px;
  }
`;
