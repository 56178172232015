/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from 'react';
import Path from 'config/clientPath';
import { Helmet } from 'react-helmet-async';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';
import { GlobalStyle } from 'styles/global-styles';
import { HomePage } from './pages/HomePage';
import { LoginPage } from './pages/LoginPage';
import ProtectedRoute from './components/ProtectedRoute';
import { GlobalContainer } from './pages/GlobalContainer';
import { CustomerPage } from './pages/CustomerPage';
import { TimesheetPage } from './pages/TimesheetPage';
import { TimesheetReportPage } from './pages/TimesheetReportPage';
import { TeamPage } from './pages/TeamPage';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import { NotFoundPage } from './components/NotFoundPage';
import { ProjectPage } from './pages/ProjectPage';
import { ProjectEditPage } from './pages/ProjectEditPage';
import { InviteMemberPage } from './pages/InviteMemberPage';
import { MemberPage } from './pages/MemberPage';
import { ProfilePage } from './pages/ProfilePage';
import { MemberEditPage } from './pages/MemberEditPage';

import { useRole } from 'utils/role-hook';
import Roles from 'constants/roles';
import { isHavingToken } from 'utils/localStorage';
import { ForgotPasswordPage } from './pages/ForgotPasswordPage';
import { TimesheetOverviewPage } from './pages/TimesheetOverviewPage';
import { TimesheetApprovalPage } from './pages/TimesheetApprovalPage';
import { ReportReview } from './pages/ReportReview';
import { ApprovalReview } from './pages/ApprovalReview';
import { CVSharePage } from './pages/CVSharePage';
import { selectLogin } from './pages/LoginPage/slice/selectors';
import { useSelector } from 'react-redux';
import { RequestStatus } from 'constants/API';
import { ROUTES_INFO } from 'constants/common';
import { AssignmentSummary } from './pages/AssignmentSummary';
import { CommitEffort } from './pages/CommitEffort';
import { TeamProposalPage } from './pages/TeamProposal';
import ProposalSharingPage from './pages/Sharing';
import { TeamProposalEditPage } from './pages/TeamProposalEdit';
import { DepartmentPage } from './pages/DepartmentPage';
import { MemberCreatePage } from './pages/MemberCreatePage';
import { JobListPage } from './pages/JobListPage';
import { JobDetail } from './pages/JobDetailPage';
import { CandidatesPage } from './pages/CandidatesPage';

const ROUTE_LIST = [
  {
    component: <Route exact path={Path.HOME} component={HomePage} />,
    role: '',
    useRegex: true,
  },

  {
    component: (
      <ProtectedRoute
        exact
        path={Path.TEAM_PROPOSAL}
        component={TeamProposalPage}
      />
    ),
    role: Roles.TEAM_PROPOSAL_ALL,
    useRegex: true,
  },
  {
    component: (
      <ProtectedRoute
        exact
        path={`${Path.TEAM_PROPOSAL}/add`}
        component={TeamProposalEditPage}
      />
    ),
    role: Roles.TEAM_PROPOSAL_ADD,
    useRegex: true,
  },
  {
    component: (
      <ProtectedRoute
        exact
        path={`${Path.TEAM_PROPOSAL}/edit/:id`}
        component={TeamProposalEditPage}
      />
    ),
    role: Roles.TEAM_PROPOSAL_UPDATE,
    useRegex: true,
  },
  {
    component: (
      <ProtectedRoute
        exact
        path={`${Path.TEAM_PROPOSAL}/:id`}
        component={ProposalSharingPage}
      />
    ),
    role: Roles.TEAM_PROPOSAL_VIEW,
    useRegex: true,
  },
  {
    component: (
      <ProtectedRoute exact path={Path.CUSTOMERS} component={CustomerPage} />
    ),
    role: ROUTES_INFO[Path.CUSTOMERS].role,
    useRegex: ROUTES_INFO[Path.CUSTOMERS].useRegex,
  },
  {
    component: (
      <ProtectedRoute exact path={Path.TIMESHEET} component={TimesheetPage} />
    ),
    role: ROUTES_INFO[Path.TIMESHEET].role,
    useRegex: ROUTES_INFO[Path.TIMESHEET].useRegex,
  },
  {
    component: (
      <ProtectedRoute exact path={Path.PROJECTS} component={ProjectPage} />
    ),
    role: ROUTES_INFO[Path.PROJECTS].role,
    useRegex: ROUTES_INFO[Path.PROJECTS].useRegex,
  },
  {
    component: (
      <ProtectedRoute
        exact
        path={`${Path.PROJECTS}/add`}
        component={ProjectEditPage}
      />
    ),
    role: Roles.PROJECT_ALL,
    useRegex: true,
  },
  {
    component: (
      <ProtectedRoute
        exact
        path={`${Path.PROJECTS}/edit/:id`}
        component={ProjectEditPage}
      />
    ),
    role: Roles.PROJECT_ALL,
    useRegex: true,
  },
  {
    component: (
      <ProtectedRoute
        exact
        path={Path.DAILY_REPORT_MONTHLY_SUMMARY}
        component={TimesheetReportPage}
      />
    ),
    role: ROUTES_INFO[Path.DAILY_REPORT_MONTHLY_SUMMARY].role,
    useRegex: ROUTES_INFO[Path.DAILY_REPORT_MONTHLY_SUMMARY].useRegex,
  },
  {
    component: (
      <ProtectedRoute
        exact
        path={Path.DAILY_REPORT_SUMMARY}
        component={TimesheetOverviewPage}
      />
    ),
    role: ROUTES_INFO[Path.DAILY_REPORT_SUMMARY].role,
    useRegex: ROUTES_INFO[Path.DAILY_REPORT_SUMMARY].useRegex,
  },
  {
    component: (
      <ProtectedRoute
        exact
        path={Path.DAILY_REPORT_APPROVAL}
        component={TimesheetApprovalPage}
      />
    ),
    role: ROUTES_INFO[Path.DAILY_REPORT_APPROVAL].role,
    useRegex: ROUTES_INFO[Path.DAILY_REPORT_APPROVAL].useRegex,
  },
  {
    component: <ProtectedRoute exact path={Path.TEAM} component={TeamPage} />,
    role: ROUTES_INFO[Path.TEAM].role,
    useRegex: ROUTES_INFO[Path.TEAM].useRegex,
  },
  {
    component: (
      <ProtectedRoute exact path={Path.MEMBERS} component={MemberPage} />
    ),
    role: ROUTES_INFO[Path.MEMBERS].role,
    useRegex: ROUTES_INFO[Path.MEMBERS].useRegex,
  },
  {
    component: (
      <ProtectedRoute
        exact
        path={Path.ADD_MEMBER}
        component={MemberCreatePage}
      />
    ),
    role: ROUTES_INFO[Path.ADD_MEMBER].role,
    useRegex: ROUTES_INFO[Path.ADD_MEMBER].useRegex,
  },
  {
    component: (
      <ProtectedRoute exact path={Path.JOB_LIST} component={JobListPage} />
    ),
    role: ROUTES_INFO[Path.JOB_LIST].role,
    useRegex: ROUTES_INFO[Path.JOB_LIST].useRegex,
  },
  {
    component: (
      <ProtectedRoute exact path={Path.JOB_DETAILS} component={JobDetail} />
    ),
    role: ROUTES_INFO[Path.JOB_DETAILS].role,
    useRegex: ROUTES_INFO[Path.JOB_DETAILS].useRegex,
  },
  {
    component: (
      <ProtectedRoute exact path={Path.PROFILE} component={ProfilePage} />
    ),
    role: ROUTES_INFO[Path.PROFILE].role,
    useRegex: ROUTES_INFO[Path.PROFILE].useRegex,
  },
  {
    component: (
      <ProtectedRoute exact path={Path.CANDIDATES} component={CandidatesPage} />
    ),
    role: ROUTES_INFO[Path.CANDIDATES].role,
    useRegex: ROUTES_INFO[Path.CANDIDATES].useRegex,
  },
  {
    component: (
      <ProtectedRoute
        exact
        path={Path.MEMBERS_EDIT}
        component={MemberEditPage}
      />
    ),
    role: ROUTES_INFO[Path.MEMBERS_EDIT].role,
    useRegex: ROUTES_INFO[Path.MEMBERS_EDIT].useRegex,
  },
  {
    component: (
      <ProtectedRoute
        exact
        path={`${Path.TIMESHEET_REPORT_REVIEW}`}
        component={ReportReview}
      />
    ),
    role: ROUTES_INFO[Path.TIMESHEET_REPORT_REVIEW].role,
    useRegex: ROUTES_INFO[Path.TIMESHEET_REPORT_REVIEW].useRegex,
  },
  {
    component: (
      <ProtectedRoute
        exact
        path={`${Path.TIMESHEET_APPROVAL_REVIEW}`}
        component={ApprovalReview}
      />
    ),
    role: ROUTES_INFO[Path.TIMESHEET_APPROVAL_REVIEW].role,
    useRegex: ROUTES_INFO[Path.TIMESHEET_APPROVAL_REVIEW].useRegex,
  },
  {
    component: (
      <ProtectedRoute exact path={Path.DEPARTMENT} component={DepartmentPage} />
    ),
    role: Roles.DEPARTMENT_ALL,
    useRegex: true,
  },
  {
    component: (
      <Route
        exact
        path={`${Path.CV_SHARE}/:id/:uuid`}
        component={CVSharePage}
      />
    ),
    // role: ROUTES_INFO[Path.CV_SHARE].role,
    // useRegex: ROUTES_INFO[Path.CV_SHARE].useRegex,
  },
  {
    component: (
      <Route exact path={`${Path.CV_SHARE}/:id/`} component={CVSharePage} />
    ),
    // role: ROUTES_INFO[Path.CV_SHARE].role,
    // useRegex: ROUTES_INFO[Path.CV_SHARE].useRegex,
  },
  {
    component: (
      <Route
        exact
        path={`${Path.FORGOT_PASSWORD}`}
        component={ForgotPasswordPage}
      />
    ),
    role: '',
    useRegex: true,
  },
  {
    component: (
      <Route
        exact
        path={`${Path.RESET_PASSWORD}/:uidb64/:token`}
        component={ForgotPasswordPage}
      />
    ),
    role: '',
    useRegex: true,
  },
  {
    component: (
      /* Invite Member Page */
      <Route
        path={`${Path.INVITE_MEMBER}/:invite_token`}
        component={InviteMemberPage}
      />
    ),
    role: '',
    useRegex: true,
  },
  {
    /* Common Route */
    component: <Route exact path={Path.LOGIN} component={LoginPage} />,
    role: '',
    useRegex: true,
  },
  {
    /* Put at end of Router list */
    component: <Route component={NotFoundPage} />,
    role: '',
    useRegex: true,
  },
];

const ROUTE_LIST_MULTIPLE_ROLES = [
  {
    component: (
      <ProtectedRoute
        exact
        path={`${Path.ASSIGNMENT_SUMMARY}`}
        component={AssignmentSummary}
      />
    ),
    role: ROUTES_INFO[Path.ASSIGNMENT_SUMMARY].role,
    roles: ROUTES_INFO[Path.ASSIGNMENT_SUMMARY].roles,
    useRegex: ROUTES_INFO[Path.ASSIGNMENT_SUMMARY].useRegex,
    useHasOneRole: ROUTES_INFO[Path.ASSIGNMENT_SUMMARY].useHasOneRole,
  },
  {
    component: (
      <ProtectedRoute
        exact
        path={`${Path.COMMIT_EFFORT}`}
        component={CommitEffort}
      />
    ),
    role: ROUTES_INFO[Path.COMMIT_EFFORT].role,
    roles: ROUTES_INFO[Path.COMMIT_EFFORT].roles,
    useRegex: ROUTES_INFO[Path.COMMIT_EFFORT].useRegex,
    useHasOneRole: ROUTES_INFO[Path.COMMIT_EFFORT].useHasOneRole,
  },
  {
    component: (
      <ProtectedRoute
        exact
        path={`${Path.ASSIGNABLE}`}
        component={AssignmentSummary}
      />
    ),
    role: ROUTES_INFO[Path.ASSIGNABLE].role,
    roles: ROUTES_INFO[Path.ASSIGNABLE].roles,
    useRegex: ROUTES_INFO[Path.ASSIGNABLE].useRegex,
    useHasOneRole: ROUTES_INFO[Path.ASSIGNABLE].useHasOneRole,
  },
  {
    component: (
      <ProtectedRoute
        exact
        path={`${Path.CUSTOMER_TIMESHEET_REPORT}`}
        component={TimesheetOverviewPage}
      />
    ),
    role: ROUTES_INFO[Path.CUSTOMER_TIMESHEET_REPORT].role,
    roles: ROUTES_INFO[Path.CUSTOMER_TIMESHEET_REPORT].roles,
    useRegex: ROUTES_INFO[Path.CUSTOMER_TIMESHEET_REPORT].useRegex,
    useHasOneRole: ROUTES_INFO[Path.CUSTOMER_TIMESHEET_REPORT].useHasOneRole,
  },
];

export function App() {
  const { i18n } = useTranslation();
  const { hasRole, hasOneRole } = useRole();

  const { loginStatus } = useSelector(selectLogin);

  const renderRouter = () => {
    const normal_routes = ROUTE_LIST.map(it => {
      if (it.role && isHavingToken()) {
        // check user has one role
        if (hasRole(it.role, it.useRegex)) {
          return it.component;
        }
        return null;
      }
      return it.component;
    });
    const routes_multiple_roles = ROUTE_LIST_MULTIPLE_ROLES.map(it => {
      // check user has one of roles
      if (it.useHasOneRole && it.roles && isHavingToken()) {
        if (hasOneRole(it.roles, it.useRegex)) {
          return it.component;
        }
        return null;
      }
      return it.component;
    });
    const all_routes = [...routes_multiple_roles, ...normal_routes];
    return all_routes;
  };

  React.useEffect(() => {
    if (loginStatus === RequestStatus.SUCCESS) renderRouter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginStatus]);

  return (
    <BrowserRouter>
      <Helmet
        titleTemplate="%s - Palette"
        defaultTitle="Palette"
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta name="description" content="Palette" />
      </Helmet>
      <GlobalContainer>
        <Switch>{renderRouter()}</Switch>
      </GlobalContainer>
      <GlobalStyle />
    </BrowserRouter>
  );
}
