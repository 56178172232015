import React, { ChangeEvent } from 'react';

// UI
import { Stack, Typography, Box, Button } from '@mui/material';

import { ReactComponent as DefaultImage120 } from 'assets/placeholder/120.svg';
import { ReactComponent as DefaultImage128 } from 'assets/placeholder/128.svg';
import { ReactComponent as DefaultImage256 } from 'assets/placeholder/256.svg';
import { ReactComponent as DefaultImage300 } from 'assets/placeholder/300.svg';
import { ReactComponent as DefaultImage512 } from 'assets/placeholder/512.svg';

import { ReactComponent as UploadIcon } from 'assets/icons/button/upload.svg';

// Library
import _ from 'lodash';
import styled from 'styled-components/macro';
import { UseFormReturn } from 'react-hook-form';

interface ISkillTableProps {
  inputName: string | 'avatar';
  form: UseFormReturn;
  size?: 120 | 128 | 256 | 300 | 512;
  inline?: boolean;
  callAPIUploadImage?: any;
}

export default function InputImage(props: ISkillTableProps) {
  const [uploadedAvatar, setUploadedAvatar] = React.useState<string>('');

  const renderAvatar = s => {
    if (uploadedAvatar) {
      if (_.isString(uploadedAvatar)) {
        return <ImageTag src={uploadedAvatar} alt="" />;
      } else if (uploadedAvatar.length > 0) {
        return (
          <WrapImage>
            <ImageTag src={URL.createObjectURL(uploadedAvatar[0])} alt="" />
          </WrapImage>
        );
      }
    }
    switch (s) {
      case 128:
        return <DefaultImage128 />;
      case 256:
        return <DefaultImage256 />;
      case 300:
        return <DefaultImage300 />;
      case 512:
        return <DefaultImage512 />;
      default:
        return <DefaultImage120 />;
    }
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    props.form.setValue(props.inputName, e.target.files);
    props?.callAPIUploadImage();
  };

  React.useEffect(() => {
    setUploadedAvatar(props.form.getValues(props.inputName));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.form.watch(props.inputName)]);

  return (
    <AvatarContainer spacing={2}>
      <label htmlFor="image-input-file">{renderAvatar(props.size)}</label>
      <input
        accept="image/*"
        style={{ display: 'none' }}
        id="image-input-file"
        multiple={false}
        type="file"
        onChange={handleFileChange}
      />
      {!props.inline && (
        <Stack spacing={2} alignSelf="flex-start">
          <Typography>
            Support for a single upload, size limit: 10MB.
            <br />
            Allowed file types: PNG, JPG, GIF
          </Typography>
          <label htmlFor="image-input-file">
            <WrapButtonUploadImage>
              <Button
                variant="outlined"
                startIcon={<UploadIcon />}
                component="span"
              >
                Change file
              </Button>
            </WrapButtonUploadImage>
          </label>
        </Stack>
      )}
    </AvatarContainer>
  );
}

const AvatarContainer = styled(Stack)`
  width: 300px;
  height: 100%;
  img {
    max-width: 100%;
  }
`;

const ImageTag = styled.img`
  border-radius: 6px;
`;

const Foreground = styled.div`
  display: none;
  position: absolute;
  background: rgb(0 0 0 / 50%);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
`;

const WrapImage = styled.div`
  position: relative;
  &:hover ${Foreground} {
    display: flex;
  }
`;

const WrapButtonUploadImage = styled(Box)`
  & .MuiButton-root {
    background-color: #fff;
    color: rgb(0 0 0 / 85%);
    border-color: #d9d9d9;
    &:hover {
      background-color: #fff;
      color: rgb(0 0 0 / 85%);
      border-color: #d9d9d9;
    }
  }
`;
