import { Circle } from '@mui/icons-material';
import { lightGreen, grey } from '@mui/material/colors';
// import { ReactComponent as RequestIcon } from 'assets/icons/plane.svg';
// import { ReactComponent as CancelIcon } from 'assets/icons/task_status/cancel.svg';
// import { ReactComponent as ApprovedIcon } from 'assets/icons/task_status/approved.svg';
// import { ReactComponent as RejectedIcon } from 'assets/icons/task_status/rejected.svg';
import Path from 'config/clientPath';
import Roles from 'constants/roles';

export const COUNTRY_LIST = {
  vi: 'Vietnam',
  ja: 'Japan',
  cn: 'China',
  sg: 'Singapore',
};

export const LEVER_SKILL_MANAGEMENT = {
  1: 'No Experience',
  2: 'Beginner',
  3: 'Intermediate',
  4: 'Advanced',
  5: 'Expert',
};

export const LANGUAGE_PAGE = {
  en: 'English',
  ja: 'Japan',
};

export const GENDER_TYPE = {
  0: 'Male',
  1: 'Female',
  2: 'Unknown',
};

export const TYPE_MEMBER = {
  O: 'Official',
  R: 'Remote',
  I: 'Intern',
  P: 'Parttime',
};

export const STATUS_OPTION = {
  1: 'Active',
  2: 'Deactive',
};

export const JOB_STATUS = {
  0: { name: 'Open', color: 'primary' },
  1: { name: 'Closed', color: 'error' },
  2: { name: 'On Hold', color: 'warning' },
};

export const STATUS_LIST = {
  1: (
    <>
      <Circle htmlColor={lightGreen[400]} fontSize="small" /> Active
    </>
  ),
  2: (
    <>
      <Circle htmlColor={grey[400]} fontSize="small" /> Deactive
    </>
  ),
};

export const ASSIGNMENT_TYPE = {
  1: 'Billable',
  2: 'Non-Billable',
};

export const REPORT_TYPE = {
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
};

export const CANDIDATE_STATUS = {
  0: 'Received',
  1: 'Screening',
  2: 'Interview',
  3: 'Offered',
  4: 'Rejected',
  5: 'Hired',
  6: 'Disqualifed',
};

export const CANDIDATE_STATUS_COLOR = {
  0: 'success',
  1: 'primary',
  2: 'default',
  3: 'warning',
  4: 'secondary',
  5: 'info',
  6: 'error',
};
export const CANDIDATE_JOB_STATUS = [
  { key: 0, name: 'Received' },
  { key: 1, name: 'Screening' },
  { key: 2, name: 'Interview' },
  { key: 3, name: 'Offered' },
  { key: 4, name: 'Rejected' },
  { key: 5, name: 'Hired' },
  { key: 6, name: 'Disqualifed' },
];

export const TAG_LIST = {
  1: {
    name: 'Coding',
    color: '#01A887',
  },
  2: {
    name: 'Testing',
    color: '#5273E0',
  },
  3: {
    name: 'Design',
    color: '#E0529C',
  },
};

export const NEW_TASK_STATUS = 1;
export const REQUESTING_TASK_STATUS = 2;
export const APPROVED_TASK_STATUS = 3;
export const FEEDBACK_TASK_STATUS = 4;
export const TASK_STATUS_IDS_FOR_ADMIN = [
  APPROVED_TASK_STATUS,
  FEEDBACK_TASK_STATUS,
  NEW_TASK_STATUS,
];
export const TASK_STATUS_LIST = {
  [NEW_TASK_STATUS]: {
    name: 'New',
    id: NEW_TASK_STATUS,
    user: {
      backgroundColor: '#437DFF',
      color: '#fff',
      label: 'Request Approval',
    },
    admin: {
      backgroundColor: '#52C41A',
      color: '#fff',
      label: 'Not Submitted',
    },
  },
  [REQUESTING_TASK_STATUS]: {
    name: 'Requesting',
    id: REQUESTING_TASK_STATUS,
    user: {
      backgroundColor: '#01A887',
      color: '#fff',
      label: 'Cancel Request',
    },
    admin: {
      backgroundColor: '#FF4D4F',
      color: '#fff',
      label: 'Wait for review',
    },
  },
  [APPROVED_TASK_STATUS]: {
    name: 'Approved',
    id: APPROVED_TASK_STATUS,
    user: {
      backgroundColor: '#F9973B',
      color: '#fff',
      label: 'Approved',
    },
    admin: {
      backgroundColor: '#AA3E19',
      color: '#fff',
      label: 'Approved',
    },
  },
  [FEEDBACK_TASK_STATUS]: {
    name: 'Feedback',
    id: FEEDBACK_TASK_STATUS,
    user: {
      backgroundColor: '#F03F37',
      color: '#fff',
      label: 'Feedback',
    },
    admin: {
      backgroundColor: '#CB2B83',
      color: '#fff',
      label: 'Feedback',
    },
  },
};

export const NON_BILLABLE_STATUS = 2;
export const BILLABLE_STATUS = 1;
export const BILLABLE_STATUS_LIST = {
  [NON_BILLABLE_STATUS]: {
    name: 'No',
    id: NON_BILLABLE_STATUS,
    backgroundColor: '#01A887',
    color: '#fff',
  },
  [BILLABLE_STATUS]: {
    name: 'Yes',
    id: BILLABLE_STATUS,
    backgroundColor: '#FF6863',
    color: '#fff',
  },
};

export const ROUTES_INFO = {
  [Path.JOB_LIST]: {
    useRegex: true,
    role: Roles.JOBS_ALL,
  },
  [Path.JOB_DETAILS]: {
    useRegex: true,
    role: Roles.JOBS_ALL,
  },
  [Path.CANDIDATES]: {
    useRegex: true,
    role: Roles.CANDIDATE_ALL,
  },
  [Path.CUSTOMERS]: {
    useRegex: true,
    role: Roles.CUSTOMER_ALL,
  },
  [Path.TIMESHEET]: {
    useRegex: true,
    role: Roles.IS_NORMAL_USER,
  },
  [Path.PROJECTS]: {
    useRegex: true,
    role: Roles.PROJECT_ALL,
  },
  [Path.DAILY_REPORT_MONTHLY_SUMMARY]: {
    useRegex: true,
    role: Roles.IS_NORMAL_USER,
  },
  [Path.DAILY_REPORT_SUMMARY]: {
    useRegex: true,
    role: Roles.WORKREPORT_APPROVE,
  },
  [Path.DAILY_REPORT_APPROVAL]: {
    useRegex: true,
    role: Roles.WORKREPORT_APPROVE,
  },
  [Path.TEAM]: {
    useRegex: true,
    role: Roles.TEAM_ALL,
  },
  [Path.MEMBERS]: {
    useRegex: true,
    role: Roles.MEMBER_ALL,
  },
  [Path.ADD_MEMBER]: {
    useRegex: true,
    role: Roles.MEMBER_ALL,
  },
  [Path.PROFILE]: {
    useRegex: true,
    role: '',
  },
  [Path.MEMBERS_EDIT]: {
    useRegex: true,
    role: '',
  },
  [Path.TIMESHEET_REPORT_REVIEW]: {
    useRegex: true,
    role: Roles.WORKREPORT_VIEW,
  },
  [Path.TIMESHEET_APPROVAL_REVIEW]: {
    useRegex: true,
    role: Roles.WORKREPORT_VIEW,
  },
  [Path.CV_SHARE]: {
    useRegex: true,
    role: Roles.CV_SHARE,
  },
  // default when use has one role then role must is null and roles is array
  [Path.ASSIGNMENT_SUMMARY]: {
    role: '',
    useRegex: false,
    roles: [Roles.IS_ADMIN, Roles.IS_LEADER],
    useHasOneRole: true,
  },
  [Path.COMMIT_EFFORT]: {
    role: '',
    useRegex: false,
    roles: [Roles.IS_ADMIN, Roles.IS_LEADER],
    useHasOneRole: true,
  },
  [Path.ASSIGNABLE]: {
    role: '',
    useRegex: false,
    roles: [Roles.IS_CUSTOMER],
    useHasOneRole: true,
  },
  [Path.CUSTOMER_TIMESHEET_REPORT]: {
    role: '',
    useRegex: false,
    roles: [Roles.IS_CUSTOMER],
    useHasOneRole: true,
  },
  [Path.TEAM_PROPOSAL]: {
    role: '',
    useRegex: false,
    roles: [Roles.TEAM_PROPOSAL_VIEW],
  },
};

export const MEMBER_ACTIVE_STATUS = '1';
export const MEMBER_RESIGN_STATUS = '2';
export const MEMBER_STATUSES = [
  {
    id: 1,
    label: 'Active',
    value: MEMBER_ACTIVE_STATUS,
  },
  {
    id: 2,
    label: 'Resign',
    value: MEMBER_RESIGN_STATUS,
  },
];
export const LEVER_SKILL = [
  {
    id: 0,
    label: '-',
  },
  {
    id: 1,
    label: '< 1 year',
  },
  {
    id: 2,
    label: '< 3 year',
  },
  {
    id: 3,
    label: '> 3 year',
  },
];

export const REGEX_VALIDATE_EMAIL =
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const ASSIGN_GET_MONTH_COUNT = 6;

export const REGEX_CHECK_HEX_CODE = /^[#]*([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/i;

export const REGEX_CHECK_NUMBER = /^[0-9]\d*(\d+)?$/i;

export const USER_ROLES = [Roles.IS_ADMIN, Roles.IS_LEADER, Roles.IS_CUSTOMER];

export const COLOR_BUTTON = {
  DELETE_EDIT_ICON: '#939393',
  // DEFAULT_COLOR_ICON: 'rgb(89, 89, 89)',
};
export const TYPE_JOB_MEMBER_FILTER = [
  {
    id: 'O',
    name: 'Official',
  },
  {
    id: 'R',
    name: 'Remote',
  },
  {
    id: 'I',
    name: 'Intern',
  },
  {
    id: 'P',
    name: 'Parttime',
  },
];

export const SPACING_ORDER = 99999;
