enum Path {
  HOME = '/',
  CONTACT = '/contact',
  CUSTOMERS = '/customers',
  PROJECTS = '/projects',
  MEMBERS = '/members',
  ADD_MEMBER = '/members/add',
  MEMBERS_EDIT = '/members/edit/:id',
  PROFILE = '/profile',
  TIMESHEET = '/task_list',
  DAILY_REPORT_MONTHLY_SUMMARY = '/monthly_summary',
  TIMESHEET_REPORT_REVIEW = '/report/review',
  TIMESHEET_APPROVAL_REVIEW = '/approval/review',
  CV_SHARE = '/cv',
  DAILY_REPORT_SUMMARY = '/summary',
  DAILY_REPORT_APPROVAL = '/approval',
  TEAM = '/teams',
  LOGIN = '/login',
  FORGOT_PASSWORD = '/forgot-password',
  RESET_PASSWORD = '/password-reset',
  INVITE_MEMBER = '/join',
  ASSIGNMENT = '/assignment',
  ASSIGNMENT_SUMMARY = '/assignment_summary',
  COMMIT_EFFORT = '/commit_effort',
  ASSIGNABLE = '/assignable',
  CUSTOMER_TIMESHEET_REPORT = '/timesheet_report',
  TEAM_PROPOSAL = '/proposals',
  DEPARTMENT = '/department',
  JOB_LIST = '/jobs',
  JOB_DETAILS = '/jobs/:id',
  CANDIDATES = '/candidates',
}

export default Path;
