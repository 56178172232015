import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { selectDepartment } from '../slice/selectors';
import _ from 'lodash';
import { useDepartmentSlice } from '../slice';
import { useEffect } from 'react';
import { useGlobalSlice } from 'app/pages/GlobalContainer/slice';
import { IDepartmentsSchema } from '../slice/types';

interface IDepartmentModalRequest {
  isOpenModal: boolean;
  setIsOpenModal: (data: boolean) => void;
  departmentDataChange: IDepartmentsSchema | undefined;
  setDepartmentDataChange: (data: IDepartmentsSchema | undefined) => void;
}

export default function DepartmentModalRequest(props: IDepartmentModalRequest) {
  const {
    isOpenModal,
    setIsOpenModal,
    departmentDataChange,
    setDepartmentDataChange,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    handleSubmit,
    reset,
    watch,
    control,
    formState: { errors },
  } = useForm();
  const { departments } = useSelector(selectDepartment);
  const {
    actions: { createDepartmentRequest, updateDepartmentRequest },
  } = useDepartmentSlice();
  const { setErrorMessages } = useGlobalSlice().actions;

  const handleSubmitDepartment = () => {
    let formData = watch();
    if (departmentDataChange) {
      const idDepartmentChange = departmentDataChange.id;
      if (formData.parent === idDepartmentChange) {
        dispatch(setErrorMessages([t('Do not set parent as itself!')]));
      } else {
        dispatch(updateDepartmentRequest({ id: idDepartmentChange, formData }));
      }
    } else {
      dispatch(createDepartmentRequest(formData));
    }
  };

  useEffect(() => {
    if (departmentDataChange) {
      const { name, parent, description } = departmentDataChange;
      reset({
        name: name,
        parent: parent && Object.keys(parent).length > 0 ? parent.id : '',
        description: description,
      });
    } else {
      reset({
        name: '',
        parent: '',
        description: '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenModal]);

  return (
    <Modal
      keepMounted
      open={isOpenModal}
      onClose={() => {}}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <StyledDiv>
        <Typography
          variant="body1"
          component="p"
          p="15px"
          borderBottom="1px solid #bdbdbd"
          fontWeight="500"
          fontSize="18px"
        >
          {departmentDataChange
            ? t('Update department')
            : t('Create department')}
        </Typography>

        <FormControl
          sx={{
            maxHeight: '450px',
            overflow: 'auto',
            width: '100%',
          }}
          onSubmit={handleSubmit(handleSubmitDepartment)}
          component="form"
        >
          <Grid container spacing={3} pb={2} pt={2} px={2}>
            <Grid item xs={8}>
              <FormLabel sx={{ display: 'flex' }}>
                {t('Name')}
                <Typography color="red" px="2px">
                  *
                </Typography>
              </FormLabel>
              <Controller
                control={control}
                name="name"
                rules={{
                  required: { value: true, message: t('This is required') },
                  maxLength: {
                    value: 60,
                    message: t('Text length does not exceed 60 words'),
                  },
                }}
                render={({ field }) => {
                  const { onChange, value } = field;
                  return (
                    <TextField
                      variant="outlined"
                      fullWidth
                      margin="dense"
                      size="small"
                      placeholder={t('Name')}
                      value={value}
                      onChange={onChange}
                      error={!!errors.name}
                      helperText={errors.name?.message}
                    />
                  );
                }}
              />
            </Grid>

            <Grid item xs={4}>
              <FormLabel>{t('Parent')}</FormLabel>
              <Controller
                control={control}
                name="parent"
                render={({ field }) => {
                  const { onBlur, onChange, value } = field;
                  return (
                    <Select
                      fullWidth
                      size="small"
                      onBlur={onBlur}
                      onChange={onChange}
                      value={`${value}`}
                      sx={{ marginTop: '8px' }}
                    >
                      {_.map(departments, (v: any, k: number) => (
                        <MenuItem key={k} value={v.id}>
                          {v.full_name}
                        </MenuItem>
                      ))}
                    </Select>
                  );
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <FormLabel>{t('Description')}</FormLabel>
              <Controller
                control={control}
                name="description"
                rules={{
                  maxLength: {
                    value: 255,
                    message: t('Text length does not exceed 255 words'),
                  },
                }}
                render={({ field }) => {
                  const { onChange, value } = field;
                  return (
                    <TextField
                      placeholder={t('Description')}
                      multiline
                      rows={6}
                      variant="outlined"
                      fullWidth
                      autoFocus
                      margin="dense"
                      value={value}
                      onChange={onChange}
                      error={!!errors.description}
                      helperText={errors.description?.message}
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
          <Stack
            direction="row"
            display="flex"
            justifyContent="flex-end"
            p="15px"
            borderTop="1px solid #BDBDBD"
            spacing={2}
          >
            <Button
              variant="contained"
              color="inherit"
              onClick={() => {
                setIsOpenModal(false);
                setDepartmentDataChange(undefined);
              }}
              type="button"
            >
              {t('Cancel')}
            </Button>
            <Button variant="contained" color="primary" type="submit">
              {t('Submit')}
            </Button>
          </Stack>
        </FormControl>
      </StyledDiv>
    </Modal>
  );
}

const StyledDiv = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  background-color: #fff;
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.16);
  min-width: 500px;
  border-radius: 5px;
`;
