import { PayloadAction } from '@reduxjs/toolkit';
import { RequestStatus } from 'constants/API';
import { REPORT_TYPE } from 'constants/common';
import _ from 'lodash';
import moment from 'moment';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import MemberSaga from './saga';
import { ISkillMetaDataSchema, MemberState } from './types';

export const initialState: MemberState = {
  getMemberListStatus: RequestStatus.IDLE,
  getMemberEffortStatus: RequestStatus.IDLE,
  getMemberWorkReportStatus: RequestStatus.IDLE,
  getMemberInfoStatus: RequestStatus.IDLE,
  getMemberHistoryAssignmentStatus: RequestStatus.IDLE,
  getMemberCurrentAssignmentStatus: RequestStatus.IDLE,
  getInviteMemberStatus: RequestStatus.IDLE,
  createMemberInfoStatus: RequestStatus.IDLE,
  updateMemberInfoStatus: RequestStatus.IDLE,
  deleteMemberInfoStatus: RequestStatus.IDLE,
  createAssignmentInfoStatus: RequestStatus.IDLE,
  updateAssignmentInfoStatus: RequestStatus.IDLE,
  deleteAssignmentInfoStatus: RequestStatus.IDLE,

  getSkillListStatus: RequestStatus.IDLE,

  getLanguageListStatus: RequestStatus.IDLE,
  getProjectRoleListStatus: RequestStatus.IDLE,
  getProjectRoleLevelListStatus: RequestStatus.IDLE,
  uploadMemberAvatarStatus: RequestStatus.IDLE,
  getDepartmentStatus: RequestStatus.IDLE,
  getLevelsStatus: RequestStatus.IDLE,
  addMemberStatus: RequestStatus.IDLE,
  getSkillManagementStatus: RequestStatus.IDLE,
  resetCreateStatus: RequestStatus.IDLE,

  messAddMember: {},
  departments: null,
  levels: null,
  memberHistoryAssignment: null,
  memberCurrentAssignment: null,
  listMember: null,
  memberInfo: null,
  workHoursInfo: null,
  monthWorkHoursReport: null,
  memberTasks: null,
  memberEfforts: null,
  skillsList: null,
  inviteList: null,
  member_meta_data: null,

  skillManagement: null,
  skillManagementList: null,
  skill_metadata: null,
  language_metadata: null,
  project_role_metadata: null,
  project_role_level_metadata: null,
  member_role_list: null,
  getMemberRoleListStatus: RequestStatus.IDLE,
  technicalSkills: null,
  getTechnicalSkillStatus: RequestStatus.IDLE,
  errorMess: [],
};

const slice = createSlice({
  name: 'member',
  initialState,
  reducers: {
    getMemberEffortRequest: (state, action: PayloadAction<any>) => {
      state.getMemberEffortStatus = RequestStatus.REQUESTING;
    },
    getMemberEffortSuccess: (state, action: PayloadAction<any>) => {
      if (action) {
        state.getMemberEffortStatus = RequestStatus.SUCCESS;
        state.memberEfforts = action.payload;
      }
    },
    getMemberEffortFail: (state, action: PayloadAction<any>) => {
      state.getMemberEffortStatus = RequestStatus.ERROR;
    },

    getMemberWorkReportRequest: (state, action: PayloadAction<any>) => {
      if (action) state.getMemberWorkReportStatus = RequestStatus.REQUESTING;
    },
    getMemberWorkReportSuccess: (state, action: PayloadAction<any>) => {
      if (action) {
        state.getMemberWorkReportStatus = RequestStatus.SUCCESS;
        let daysHasData: string[] = [];
        let reportData: number[] = [];
        const { report_type, hours, date_start, date_end } = action.payload;
        if (report_type === REPORT_TYPE.DAY) {
          const startDate = moment(date_start, 'YYYY-MM-DD');
          const endDate = moment(date_end, 'YYYY-MM-DD');
          const diffDates = Math.abs(endDate.diff(startDate, 'days'));
          let monthDate = startDate.clone();
          // let monthDate = moment().startOf('month');
          // _.times(monthDate.daysInMonth(), function () {
          _.times(diffDates + 1, function () {
            const reportDate = monthDate.format('MM/DD');
            const hoursOfReportDate = hours.find(v => v.date === reportDate);
            daysHasData = [...daysHasData, monthDate.format('YYYY/MM/DD')];
            reportData = [...reportData, hoursOfReportDate?.duration || 0];
            monthDate.add(1, 'day');
          });
        } else if (
          report_type === REPORT_TYPE.WEEK ||
          report_type === REPORT_TYPE.MONTH
        ) {
          hours.map(v => {
            daysHasData = [...daysHasData, v.date];
            reportData = [...reportData, v.duration];
            return true;
          });
        }

        state.workHoursInfo = {
          labels: daysHasData,
          data: reportData,
        };
        state.monthWorkHoursReport = hours;
        state.memberTasks = action.payload.tasks;
      }
    },
    getMemberWorkReportFail: (state, action: PayloadAction<any>) => {
      state.getMemberWorkReportStatus = RequestStatus.ERROR;
    },

    getMemberListRequest: (state, action: PayloadAction<any>) => {
      if (action) state.getMemberListStatus = RequestStatus.REQUESTING;
    },
    getMemberListSuccess: (state, action: PayloadAction<any>) => {
      if (action) {
        state.getMemberListStatus = RequestStatus.SUCCESS;
        state.listMember = action.payload;
      }
    },
    getMemberListMetaDataSuccess: (state, action: PayloadAction<any>) => {
      if (action) {
        state.getMemberListStatus = RequestStatus.SUCCESS;
        state.member_meta_data = action.payload;
      }
    },
    getMemberListFail: (state, action: PayloadAction<any>) => {
      state.getMemberListStatus = RequestStatus.ERROR;
    },

    getMemberInfoRequest: (state, action: PayloadAction<any>) => {
      if (action) state.getMemberInfoStatus = RequestStatus.REQUESTING;
    },
    getMemberInfoSuccess: (state, action: PayloadAction<any>) => {
      if (action) {
        state.getMemberInfoStatus = RequestStatus.SUCCESS;
        state.memberInfo = action.payload;
      }
    },
    getMemberInfoFail: (state, action: PayloadAction<any>) => {
      state.getMemberCurrentAssignmentStatus = RequestStatus.ERROR;
    },
    getMemberHistoryAssignmentFail: (state, action: PayloadAction<any>) => {
      state.getMemberHistoryAssignmentStatus = RequestStatus.ERROR;
    },
    getMemberHistoryAssignmentRequest: (state, action: PayloadAction<any>) => {
      if (action)
        state.getMemberHistoryAssignmentStatus = RequestStatus.REQUESTING;
    },
    getMemberHistoryAssignmentSuccess: (state, action: PayloadAction<any>) => {
      if (action) {
        state.getMemberHistoryAssignmentStatus = RequestStatus.SUCCESS;
        state.memberHistoryAssignment = action.payload;
      }
    },
    getMemberCurrentAssignmentRequest: (state, action: PayloadAction<any>) => {
      if (action)
        state.getMemberCurrentAssignmentStatus = RequestStatus.REQUESTING;
    },
    getMemberCurrentAssignmentSuccess: (state, action: PayloadAction<any>) => {
      if (action) {
        state.getMemberCurrentAssignmentStatus = RequestStatus.SUCCESS;
        state.memberCurrentAssignment = action.payload;
      }
    },
    getMemberCurrentAssignmentFail: (state, action: PayloadAction<any>) => {
      state.getMemberCurrentAssignmentStatus = RequestStatus.ERROR;
    },

    createMemberInfoRequest: (state, action: PayloadAction<any>) => {
      if (action) state.createMemberInfoStatus = RequestStatus.REQUESTING;
    },
    createMemberInfoSuccess: (state, action: PayloadAction<any>) => {
      if (action) state.createMemberInfoStatus = RequestStatus.SUCCESS;
    },
    createMemberInfoFail: (state, action: PayloadAction<any>) => {
      state.createMemberInfoStatus = RequestStatus.ERROR;
    },
    updateMemberInfoRequest: (state, action: PayloadAction<any>) => {
      if (action) state.updateMemberInfoStatus = RequestStatus.REQUESTING;
    },
    updateMemberInfoSuccess: (state, action: PayloadAction<any>) => {
      if (action) state.updateMemberInfoStatus = RequestStatus.SUCCESS;
      state.memberInfo = action.payload;
    },
    updateMemberInfoFail: (state, action: PayloadAction<any>) => {
      state.updateMemberInfoStatus = RequestStatus.ERROR;
    },
    deleteMemberInfoRequest: (state, action: PayloadAction<any>) => {
      if (action) state.deleteMemberInfoStatus = RequestStatus.REQUESTING;
    },
    deleteMemberInfoSuccess: (state, action: PayloadAction<any>) => {
      state.deleteMemberInfoStatus = RequestStatus.SUCCESS;
    },
    deleteMemberInfoFail: (state, action: PayloadAction<any>) => {
      state.deleteMemberInfoStatus = RequestStatus.ERROR;
    },
    createAssignmentInfoRequest: (state, action: PayloadAction<any>) => {
      if (action) state.createAssignmentInfoStatus = RequestStatus.REQUESTING;
    },
    createAssignmentInfoSuccess: (state, action: PayloadAction<any>) => {
      if (action) state.createAssignmentInfoStatus = RequestStatus.SUCCESS;
    },
    createAssignmentInfoFail: (state, action: PayloadAction<any>) => {
      state.createAssignmentInfoStatus = RequestStatus.ERROR;
    },
    updateAssignmentInfoRequest: (state, action: PayloadAction<any>) => {
      if (action) state.updateAssignmentInfoStatus = RequestStatus.REQUESTING;
    },
    updateAssignmentInfoSuccess: (state, action: PayloadAction<any>) => {
      if (action) state.updateAssignmentInfoStatus = RequestStatus.SUCCESS;
    },
    updateAssignmentInfoFail: (state, action: PayloadAction<any>) => {
      state.updateAssignmentInfoStatus = RequestStatus.ERROR;
    },
    deleteAssignmentInfoRequest: (state, action: PayloadAction<any>) => {
      if (action) state.deleteAssignmentInfoStatus = RequestStatus.REQUESTING;
    },
    deleteAssignmentInfoSuccess: (state, action: PayloadAction<any>) => {
      state.deleteAssignmentInfoStatus = RequestStatus.SUCCESS;
    },
    deleteAssignmentInfoFail: (state, action: PayloadAction<any>) => {
      state.deleteMemberInfoStatus = RequestStatus.ERROR;
    },

    // reset status
    resetBasicInfoStatus: state => {
      state.getMemberListStatus = RequestStatus.IDLE;
      state.createMemberInfoStatus = RequestStatus.IDLE;
      state.updateMemberInfoStatus = RequestStatus.IDLE;
      state.deleteMemberInfoStatus = RequestStatus.IDLE;
      state.uploadMemberAvatarStatus = RequestStatus.IDLE;
      state.getDepartmentStatus = RequestStatus.IDLE;
    },
    resetMemberInfoRequestStatus: state => {
      state.getMemberListStatus = RequestStatus.IDLE;
      state.createMemberInfoStatus = RequestStatus.IDLE;
      state.updateMemberInfoStatus = RequestStatus.IDLE;
      state.deleteMemberInfoStatus = RequestStatus.IDLE;
      state.uploadMemberAvatarStatus = RequestStatus.IDLE;
      state.listMember = null;
      state.getMemberRoleListStatus = RequestStatus.IDLE;
      state.getDepartmentStatus = RequestStatus.IDLE;
      state.getTechnicalSkillStatus = RequestStatus.IDLE;
      state.member_role_list = null;
      state.departments = null;
      state.technicalSkills = null;
      state.errorMess = [];
    },
    // reset status
    resetAssignmentInfoRequestStatus: state => {
      state.createAssignmentInfoStatus = RequestStatus.IDLE;
      state.updateAssignmentInfoStatus = RequestStatus.IDLE;
      state.deleteAssignmentInfoStatus = RequestStatus.IDLE;
    },
    resetMemberStatus: state => {
      state.addMemberStatus = RequestStatus.IDLE;
      state.updateMemberInfoStatus = RequestStatus.IDLE;
      state.messAddMember = {};
    },

    // get common data
    getSkillListRequest: (state, action: PayloadAction<any>) => {
      if (action) state.getSkillListStatus = RequestStatus.REQUESTING;
    },
    getSkillListSuccess: (state, action: PayloadAction<any>) => {
      state.getSkillListStatus = RequestStatus.SUCCESS;
      if (action) {
        state.skillsList = action.payload;
        const d: { [key: number]: ISkillMetaDataSchema } = {};
        _.forEach(action.payload, it => {
          if (`${_.get(it, 'category.id')}` in d) {
            d[_.get(it, 'category.id')].skills.push({
              id: it.id,
              name: it.name,
            });
          } else {
            d[_.get(it, 'category.id')] = {
              id: it.category.id,
              name: it.category.name,
              skills: [
                {
                  id: it.id,
                  name: it.name,
                },
              ],
            };
          }
        });

        state.skill_metadata = _.values<ISkillMetaDataSchema>(d);
      }
    },
    getSkillListFail: (state, action: PayloadAction<any>) => {
      state.getSkillListStatus = RequestStatus.ERROR;
    },

    getLanguageListRequest: (state, action: PayloadAction<any>) => {
      if (action) state.getLanguageListStatus = RequestStatus.REQUESTING;
    },
    getLanguageListSuccess: (state, action: PayloadAction<any>) => {
      state.getLanguageListStatus = RequestStatus.SUCCESS;
      if (action) {
        state.language_metadata = action.payload;
      }
    },
    getLanguageListFail: (state, action: PayloadAction<any>) => {
      state.getLanguageListStatus = RequestStatus.ERROR;
    },

    getProjectRoleListRequest: (state, action: PayloadAction<any>) => {
      if (action) state.getProjectRoleListStatus = RequestStatus.REQUESTING;
    },
    getProjectRoleListSuccess: (state, action: PayloadAction<any>) => {
      state.getProjectRoleListStatus = RequestStatus.SUCCESS;
      if (action) {
        state.project_role_metadata = action.payload;
      }
    },
    getProjectRoleListFail: (state, action: PayloadAction<any>) => {
      state.getProjectRoleListStatus = RequestStatus.ERROR;
    },
    getProjectRoleLevelListRequest: (state, action: PayloadAction<any>) => {
      if (action)
        state.getProjectRoleLevelListStatus = RequestStatus.REQUESTING;
    },
    getProjectRoleLevelListSuccess: (state, action: PayloadAction<any>) => {
      state.getProjectRoleLevelListStatus = RequestStatus.SUCCESS;
      if (action) {
        state.project_role_level_metadata = action.payload;
      }
    },
    getProjectRoleLevelListFail: (state, action: PayloadAction<any>) => {
      state.getProjectRoleLevelListStatus = RequestStatus.ERROR;
    },
    getInviteMemberRequest: (state, action: PayloadAction<any>) => {
      if (action) state.getInviteMemberStatus = RequestStatus.REQUESTING;
    },
    getInviteMemberSuccess: (state, action: PayloadAction<any>) => {
      state.getInviteMemberStatus = RequestStatus.SUCCESS;
      if (action) {
        state.inviteList = action.payload;
      }
    },
    getInviteMemberFail: (state, action: PayloadAction<any>) => {
      state.getInviteMemberStatus = RequestStatus.ERROR;
    },
    uploadMemberAvatarRequest: (state, action: PayloadAction<any>) => {
      if (action) state.uploadMemberAvatarStatus = RequestStatus.REQUESTING;
    },
    uploadMemberAvatarSuccess: (state, action: PayloadAction<any>) => {
      state.uploadMemberAvatarStatus = RequestStatus.SUCCESS;
      state.memberInfo = state.memberInfo
        ? { ...state.memberInfo, avatar: action.payload.avatar }
        : null;
    },
    uploadMemberAvatarFail: (state, action: PayloadAction<any>) => {
      state.uploadMemberAvatarStatus = RequestStatus.ERROR;
    },

    getMemberRoleListRequest: (state, action: PayloadAction<any>) => {
      if (action) state.getMemberRoleListStatus = RequestStatus.REQUESTING;
    },
    getMemberRoleListSuccess: (state, action: PayloadAction<any>) => {
      state.getMemberRoleListStatus = RequestStatus.SUCCESS;
      if (action) {
        state.member_role_list = action.payload;
      }
    },
    getMemberRoleListFail: (state, action: PayloadAction<any>) => {
      state.getMemberRoleListStatus = RequestStatus.ERROR;
    },

    getDepartmentRequest: (state, action: PayloadAction<any>) => {
      if (action) state.getDepartmentStatus = RequestStatus.REQUESTING;
    },
    getDepartmentSuccess: (state, action: PayloadAction<any>) => {
      state.getDepartmentStatus = RequestStatus.SUCCESS;
      state.departments = action.payload;
    },
    getDepartmentFail: (state, action: PayloadAction<any>) => {
      state.getDepartmentStatus = RequestStatus.ERROR;
    },

    getLevelsRequest: (state, action: PayloadAction<any>) => {
      if (action) state.getLevelsStatus = RequestStatus.REQUESTING;
    },
    getLevelsSuccess: (state, action: PayloadAction<any>) => {
      state.getLevelsStatus = RequestStatus.SUCCESS;
      state.levels = action.payload;
    },
    getLevelsFail: (state, action: PayloadAction<any>) => {
      state.getLevelsStatus = RequestStatus.ERROR;
    },
    addMemberRequest: (state, action: PayloadAction<any>) => {
      if (action) state.addMemberStatus = RequestStatus.REQUESTING;
    },
    addMemberSuccess: (state, action: PayloadAction<any>) => {
      state.addMemberStatus = RequestStatus.SUCCESS;
      state.messAddMember = action.payload;
    },
    addMemberFail: (state, action: PayloadAction<any>) => {
      state.addMemberStatus = RequestStatus.ERROR;
      state.messAddMember = action.payload;
    },
    getTechnicalSkillRequest: (state, action: PayloadAction<any>) => {
      if (action) state.getTechnicalSkillStatus = RequestStatus.REQUESTING;
    },
    getTechnicalSkillSuccess: (state, action: PayloadAction<any>) => {
      if (action) {
        state.getTechnicalSkillStatus = RequestStatus.SUCCESS;
        state.technicalSkills = action.payload;
      }
    },
    getTechnicalSkillFail: (state, action: PayloadAction<any>) => {
      if (action) state.getTechnicalSkillStatus = RequestStatus.ERROR;
    },

    getSkillManagementRequest: (state, action: PayloadAction<any>) => {
      if (action) state.getSkillManagementStatus = RequestStatus.REQUESTING;
    },
    getSkillManagementSuccess: (state, action: PayloadAction<any>) => {
      if (action) {
        state.skillManagementList = action.payload;

        const d: { [key: number]: ISkillMetaDataSchema } = {};
        _.forEach(action.payload, it => {
          if (`${_.get(it, 'category.id')}` in d) {
            d[_.get(it, 'category.id')].skills.push({
              id: it.id,
              name: it.name,
            });
          } else {
            d[_.get(it, 'category.id')] = {
              id: it.category.id,
              name: it.category.name,
              skills: [
                {
                  id: it.id,
                  name: it.name,
                },
              ],
            };
          }
        });
        state.skillManagement = _.values<ISkillMetaDataSchema>(d);
        state.getSkillManagementStatus = RequestStatus.SUCCESS;
      }
    },
    getSkillManagementFail: (state, action: PayloadAction<any>) => {
      state.getSkillManagementStatus = RequestStatus.ERROR;
    },

    // reset status create usser
    resetCreateStatus: state => {
      state.getDepartmentStatus = RequestStatus.IDLE;
      state.getProjectRoleListStatus = RequestStatus.IDLE;
      state.getLevelsStatus = RequestStatus.IDLE;
      state.addMemberStatus = RequestStatus.IDLE;
      state.departments = null;
      state.levels = null;
      state.project_role_metadata = null;
    },

    resetTimesheetOverviewMemberStatus: state => {
      state.memberTasks = null;
      state.getMemberWorkReportStatus = RequestStatus.IDLE;
      state.getMemberListStatus = RequestStatus.IDLE;
    },

    resetApprovalReviewMemberStatus: state => {
      state.memberInfo = null;
      state.getMemberInfoStatus = RequestStatus.IDLE;
    },

    resetMemberReportStatus: state => {
      state.getMemberEffortStatus = RequestStatus.IDLE;
      state.getMemberWorkReportStatus = RequestStatus.IDLE;
      state.getMemberInfoStatus = RequestStatus.IDLE;
      state.workHoursInfo = null;
      state.monthWorkHoursReport = null;
      state.memberTasks = null;
      state.memberInfo = null;
      state.memberEfforts = null;
    },

    resetTaskListStatus: state => {
      state.getMemberWorkReportStatus = RequestStatus.IDLE;
      state.workHoursInfo = null;
      state.monthWorkHoursReport = null;
      state.memberTasks = null;
    },
  },
});

export default slice.actions;

export const useMemberSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: MemberSaga });
  return { actions: slice.actions };
};
