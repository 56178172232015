import Path from 'config/clientPath';
import { RequestStatus } from 'constants/API';
import { ASSIGN_GET_MONTH_COUNT, REGEX_CHECK_HEX_CODE } from 'constants/common';
import roles from 'constants/roles';
import _ from 'lodash';
import moment from 'moment';
import { IHierarchyData } from 'types/common';

export const checkIsRequesting = (statuses: string[]) => {
  let isRequesting = false;
  _.forEach(statuses, status => {
    if (status === RequestStatus.REQUESTING) {
      isRequesting = true;
      return;
    }
  });
  return isRequesting;
};

export const roundDecimal = (number, decimal_place: number = 1) => {
  const round_number = +('1' + '0'.repeat(decimal_place));
  return Math.round((number + Number.EPSILON) * round_number) / round_number;
};

export const hexToRgba = (hex, opacity) => {
  if (!REGEX_CHECK_HEX_CODE.test(hex)) {
    return '';
  }
  hex = hex.replace('#', '');
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  let rgba: any[] = [];
  rgba.push(parseInt(hex.slice(0, 2), 16));
  rgba.push(parseInt(hex.slice(2, 4), 16));
  rgba.push(parseInt(hex.slice(4, 6), 16));
  rgba.push(opacity);
  return 'rgb(' + rgba.toString() + ')';
};

export const getHalfYearMonths = (currentMonth: moment.Moment) => {
  const currentMonthNumber = _.toInteger(
    currentMonth.clone().subtract(0, 'month').format('M'),
  );
  // get month start base on current month in first half year or not
  const _monthStart =
    currentMonthNumber <= 6
      ? currentMonth.clone().startOf('year')
      : currentMonth
          .clone()
          .startOf('year')
          .add(ASSIGN_GET_MONTH_COUNT, 'month');

  const _months = _.times(ASSIGN_GET_MONTH_COUNT).map(v => {
    const newMonth = _monthStart.clone().add(v, 'month');
    return {
      month: newMonth,
      slashMonthString: newMonth.format('YYYY/MM'),
      dashMonthString: newMonth.format('YYYY-MM'),
    };
  });
  return _months;
};

export const randomIntegerInRange = (min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

// get default route based on role of user
export const getDefaultRoute = (userRole: string): string => {
  let defaultRoute = Path.TIMESHEET;
  switch (userRole) {
    case roles.IS_ADMIN:
      defaultRoute = Path.CUSTOMERS;
      break;
    case roles.IS_LEADER:
    case roles.IS_NORMAL_USER:
      defaultRoute = Path.TIMESHEET;
      break;
    case roles.IS_CUSTOMER:
      defaultRoute = Path.ASSIGNABLE;
      break;
  }
  return defaultRoute;
};

export const isChildrenDepartment = (
  hierarchyData: IHierarchyData[] | [],
  parentId: number,
) => {
  const hierarchySelected = hierarchyData?.find(
    (item: any) => item.hierarchy[item.hierarchy.length - 1] === parentId,
  );
  return hierarchySelected ? hierarchySelected.isOpen : false;
};

export const handleParamSearch = (data: any) => {
  return Object.keys(data)
    .map(key => (data[key] ? key + '=' + data[key] : ''))
    .filter(o => o)
    .join('&');
};

export const saveFile = summary => {
  const a = document.createElement('a');
  if (summary) {
    const urlObject = new URL(summary); // Parse the URL string

    a.href = summary;
    a.target = '_blank';
    a.download = urlObject.pathname.split('/').pop() || '';
  }
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const parseNumber = (value: number) => {
  return Number.isInteger(value)
    ? value
    : value
        .toFixed(2)
        .replace(/\.10$/, '')
        .replace(/\.([1-9])0$/, '.$1');
};
