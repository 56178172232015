import React, { useEffect } from 'react';

// UI
import {
  Box,
  Button,
  Stack,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  TableHead,
  Paper,
} from '@mui/material';
import styled from 'styled-components/macro';
import { ITag } from 'app/pages/TagPage/slice/types';
import { HourTag } from 'app/components/HourTag';
import { useSelector } from 'react-redux';
import { TagComponent } from 'app/components/TagComponent';
import _ from 'lodash';
import { ReactComponent as EditIcon } from 'assets/icons/button/edit.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/button/delete.svg';
import { useTranslation } from 'react-i18next';
import { APPROVED_TASK_STATUS, REQUESTING_TASK_STATUS } from 'constants/common';
import { checkIsRequesting } from 'utils/helper';
import { Loader } from 'app/components/Loader';
import { selectTimesheet } from '../slice/selectors';
import { ITimesheet } from '../slice/types';

export interface IMember {
  id: number;
  avatar: string;
  code: string;
  full_name: string;
  member_code: string;
  shift_end: string;
  shift_start: string;
  type: string;
  type_display: string;
}

interface IMemberTasksSchema {
  id: number;
  member: IMember;
  duration: number;
  date: string;
  description: string;
  project: {
    name: string;
  };
  tags: ITag[];
  color?: string;
  subject?: string;
}

interface ITasksModal {
  open: boolean;
  selectViewMoreData: IMemberTasksSchema[];
  setSeletedTaskLogData: (value: ITimesheet | undefined) => void;
  setIsShowModalTaskLog: (value: boolean) => void;
  setIsShowDeleteTaskDialog: (value: boolean) => void;
  setDateString: (value: string) => void;
  onClose: Function;
}

export function TasksModal(props: ITasksModal) {
  const {
    open,
    selectViewMoreData,
    onClose,
    setSeletedTaskLogData,
    setIsShowModalTaskLog,
    setIsShowDeleteTaskDialog,
    setDateString,
  } = props;
  const { t } = useTranslation();
  const groupTasksByProject = tasks => {
    return tasks?.reduce((acc, task) => {
      const projectName = task?.project?.name;
      if (!acc[projectName]) {
        acc[projectName] = [];
      }
      acc[projectName].push(task);
      return acc;
    }, {});
  };
  const groupedTasks = groupTasksByProject(selectViewMoreData);
  const { addTimesheetStatus, updateTimesheetStatus, deleteTimesheetStatus } =
    useSelector(selectTimesheet);

  const isLoading = checkIsRequesting([
    addTimesheetStatus,
    updateTimesheetStatus,
    deleteTimesheetStatus,
  ]);

  useEffect(() => {
    if (Object.keys(groupTasksByProject(selectViewMoreData)).length === 0) {
      onClose();
      setDateString('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectViewMoreData]);

  return (
    <>
      <Dialog open={open} maxWidth="xl" fullWidth scroll="paper">
        <Loader open={isLoading} />
        <DialogTitle>
          <Typography variant="h5" component="div">
            {t('Task Logs')}
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <Stack spacing={1}>
            {Object.keys(groupedTasks)?.map(projectName => {
              const totalHours = _.sumBy(groupedTasks[projectName], o =>
                _.toNumber(o.duration),
              );
              return (
                <>
                  <Box
                    display="flex"
                    flexDirection="row"
                    sx={{ padding: '10px' }}
                  >
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography
                        variant="h6"
                        gutterBottom
                        component="span"
                        mb={0}
                      >
                        {projectName}
                      </Typography>
                      <Typography variant="h6" gutterBottom component="span">
                        <HourTag
                          data={{
                            backgroundColor: '#BDD8FF',
                            label: `${totalHours}h`,
                          }}
                        ></HourTag>
                      </Typography>
                    </Stack>
                  </Box>
                  <WrapTable>
                    <TableContainer component={Paper}>
                      <Table stickyHeader>
                        <TableHead>
                          <TableRow>
                            <TableCell sx={{ minWidth: 200 }} width={500}>
                              {t('Ticket ID')}
                            </TableCell>
                            <TableCell
                              sx={{ minWidth: 100, maxWidth: 100 }}
                              width={100}
                            >
                              {t('Duration')}
                            </TableCell>
                            <TableCell width={200}>{t('Tag')}</TableCell>
                            <TableCell
                              sx={{ minWidth: 50 }}
                              width={50}
                              style={{ textAlign: 'center' }}
                            >
                              {t('Action')}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {groupedTasks[projectName]?.map((task, idx) => {
                            return (
                              <TableRow key={`task_calendar_${idx}`}>
                                <TableCell>{task?.subject}</TableCell>
                                <TableCell>{`${task?.duration}h`}</TableCell>
                                <TableCell>
                                  <Stack spacing={1}>
                                    {task?.tags?.map((v, k) => (
                                      <TagComponent data={v} key={k} />
                                    ))}
                                  </Stack>
                                </TableCell>

                                <TableCell
                                  sx={{ minWidth: 50 }}
                                  width={50}
                                  style={{ textAlign: 'center' }}
                                >
                                  <Stack
                                    direction="row"
                                    spacing={1}
                                    justifyContent={'center'}
                                  >
                                    <IconButtonCustom
                                      disabled={
                                        _.toNumber(task?.status) ===
                                          APPROVED_TASK_STATUS ||
                                        _.toNumber(task?.status) ===
                                          REQUESTING_TASK_STATUS
                                      }
                                      onClick={() => {
                                        setSeletedTaskLogData(task);
                                        setIsShowModalTaskLog(true);
                                      }}
                                    >
                                      <EditIcon />
                                    </IconButtonCustom>
                                    <IconButtonCustom
                                      disabled={
                                        _.toNumber(task?.status) ===
                                          APPROVED_TASK_STATUS ||
                                        _.toNumber(task?.status) ===
                                          REQUESTING_TASK_STATUS
                                      }
                                      color="error"
                                      onClick={() => {
                                        setSeletedTaskLogData(task);
                                        setIsShowDeleteTaskDialog(true);
                                      }}
                                    >
                                      <DeleteIcon />
                                    </IconButtonCustom>
                                  </Stack>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </WrapTable>
                </>
              );
            })}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Stack
            direction="row"
            justifyContent="flex-end"
            flexGrow={1}
            sx={{ marginRight: '16px', marginBottom: '8px', marginTop: '8px' }}
          >
            <Button
              onClick={() => {
                onClose();
                setDateString('');
              }}
              variant="outlined"
            >
              {t('Close')}
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  );
}

const IconButtonCustom = styled(IconButton)`
  &.Mui-disabled {
    cursor: not-allowed !important;
    pointer-events: unset !important;
  }
`;

const WrapTable = styled(Box)`
  margin-bottom: 16px;
`;
