import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import {
  CloudUpload as CloudUploadIcon,
  Delete as DeleteIcon,
  Save as SaveIcon,
} from '@mui/icons-material';
import UISettings from 'styles/setting';

import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { Controller, useForm } from 'react-hook-form';

import { useDropzone } from 'react-dropzone';
import { useGlobalSlice } from '../../../GlobalContainer/slice';
import { useDispatch } from 'react-redux';
import moment from 'moment/moment';
import { useParams } from 'react-router-dom';
import InputImage from '../../../../components/InputImage';
import useRecruitment from 'app/pages/RecruitmentsPage/useRecruitment';
import { RecruitmentCandidateSchema } from 'app/pages/RecruitmentsPage/slice/type';
import { TextFieldRegister } from 'app/components/TextFieldRegister';
import {
  GENDER_TYPE,
  REGEX_CHECK_NUMBER,
  REGEX_VALIDATE_EMAIL,
} from 'constants/common';
import { DatePickerFiled } from 'app/components/DatePickerField';
import { Colors } from 'app/pages/HomePage/Components/Variables';
import { RequestStatus } from 'constants/API';

export default function ModalUploadCvByManual({ statusJob, setCloseModal }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const uploadForm = useForm();
  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = uploadForm;
  const { sourceCV, skillsList, language_metadata } = useRecruitment();

  const { setErrorMessages } = useGlobalSlice().actions;
  const { createCandidate, createCandidateStatus } = useRecruitment();
  const { id }: { id: string } = useParams();

  const onDrop = acceptedFiles => {
    acceptedFiles.filter(file => {
      if (file.size > 10 * 1024 * 1024) {
        dispatch(
          setErrorMessages([t('File size exceeds maximum limit! (10MB)')]),
        );
        return false;
      } else {
        setValue('cv_file', file);
        return true;
      }
    });
  };

  const { getRootProps } = useDropzone({
    accept: { 'application/pdf': ['.pdf'] },
    onDrop: onDrop,
    multiple: false,
  });

  const handleRemoveFile = () => {
    setValue('cv_file', '');
  };

  const onSave = async data => {
    let formData: FormData = new FormData();
    const clonedObject = _.omit(data, [
      'dob',
      'applied_date',
      'attached_cv',
      'profile_picture',
      'skills',
      'language_proficiency',
    ]);
    _.forEach(clonedObject, (v, k) => {
      if (v !== undefined) {
        formData.append(k, v);
      }
    });
    formData.append('status', statusJob.toString());
    formData.append('job', id);
    formData.append('skills', _.map(data.skills, 'id'));
    formData.append(
      'language_proficiency',
      _.map(data.language_proficiency, 'id'),
    );

    const dateFieldToAppend = ['dob', 'applied_date'];
    dateFieldToAppend.forEach(field => {
      formData.append(
        field,
        getValues(field)
          ? moment(getValues(field)).format(UISettings.dateFormat)
          : '',
      );
    });
    if (typeof uploadForm.getValues('profile_picture') !== 'string') {
      formData.append(
        'profile_picture',
        getValues('profile_picture') ? getValues('profile_picture')[0] : '',
      );
    }

    createCandidate(formData);
  };

  return (
    <Box
      sx={{ border: 'none', padding: 0, maxHeight: 700 }}
      component="form"
      id="upload_cv_form"
      onSubmit={handleSubmit(onSave)}
    >
      <Grid container spacing={1} pt={1} padding={2}>
        <Grid item xs={6}>
          <InputImage
            inputName={'profile_picture'}
            form={uploadForm}
            size={256}
          />
        </Grid>
        <Grid item xs={6}>
          <Grid container xs={12} spacing={1}>
            <Grid item xs={12}>
              <InputLabel required>{t('Full name')}</InputLabel>
              <TextFieldRegister
                error={!!errors.full_name}
                helperText={errors.full_name?.message}
                register={register<keyof RecruitmentCandidateSchema>(
                  'full_name',
                  {
                    required: t('This field is required') as string,
                    maxLength: {
                      value: 255,
                      message: t(
                        'Please enter no more than {{count}} characters.',
                        { count: 255 },
                      ),
                    },
                  },
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel required>{t('Email')}</InputLabel>
              <TextFieldRegister
                register={register<keyof RecruitmentCandidateSchema>('email', {
                  required: t('This field is required') as string,
                  pattern: {
                    value: REGEX_VALIDATE_EMAIL,
                    message: t('Email is invalid'),
                  },
                })}
                error={!!errors.email}
                helperText={errors.email?.message}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel>{t('Phone')}</InputLabel>
              <TextFieldRegister
                error={!!errors.phone}
                helperText={errors.phone?.message}
                register={register<keyof RecruitmentCandidateSchema>('phone', {
                  pattern: {
                    value: REGEX_CHECK_NUMBER,
                    message: t('Please enter an integer'),
                  },
                  maxLength: {
                    value: 12,
                    message: t(
                      'Please enter no more than {{count}} characters.',
                      { count: 12 },
                    ),
                  },
                })}
              />
            </Grid>

            <Grid item xs={6}>
              <FormLabel>{t('Gender')}</FormLabel>
              <FormControl fullWidth margin="dense">
                <Controller
                  control={control}
                  name="gender"
                  render={({ field }) => {
                    const { onBlur, onChange, value } = field;
                    return (
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        size="small"
                        onBlur={onBlur}
                        value={value}
                        onChange={onChange}
                      >
                        {_.map(GENDER_TYPE, (gender, idx) => (
                          <MenuItem value={idx} key={idx}>
                            {gender}
                          </MenuItem>
                        ))}
                      </Select>
                    );
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <InputLabel>{t('Birthday')}</InputLabel>
              <FormControl fullWidth margin="dense">
                <Controller
                  control={control}
                  name={`dob`}
                  defaultValue={null}
                  render={({ field: { onChange, value } }) => (
                    <DatePickerFiled
                      value={value}
                      onChange={onChange}
                      disableFuture={true}
                      noEntry={true}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <InputLabel>{t('Current Salary')}</InputLabel>
              <TextFieldRegister
                register={register<keyof RecruitmentCandidateSchema>(
                  'current_salary',
                  {
                    maxLength: {
                      value: 50,
                      message: t(
                        'Please enter no more than {{count}} characters.',
                        { count: 50 },
                      ),
                    },
                  },
                )}
                error={!!errors.current_salary}
                helperText={errors.current_salary?.message}
              />
            </Grid>
            <Grid item xs={6}>
              <InputLabel>{t('Applied Date')}</InputLabel>
              <FormControl fullWidth margin="dense">
                <Controller
                  control={control}
                  name={`applied_date`}
                  defaultValue={null}
                  render={({ field: { onChange, value } }) => (
                    <DatePickerFiled
                      value={value}
                      onChange={onChange}
                      noEntry={true}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={6}>
          <InputLabel>{t('Education')}</InputLabel>
          <TextFieldRegister
            register={register<keyof RecruitmentCandidateSchema>('education', {
              maxLength: {
                value: 255,
                message: t('Please enter no more than {{count}} characters.', {
                  count: 255,
                }),
              },
            })}
            error={!!errors.education}
            helperText={errors.education?.message}
          />
        </Grid>

        <Grid item xs={6}>
          <InputLabel>{t('Current Job Level')}</InputLabel>
          <TextFieldRegister
            register={register<keyof RecruitmentCandidateSchema>(
              'current_level',
              {
                maxLength: {
                  value: 255,
                  message: t(
                    'Please enter no more than {{count}} characters.',
                    {
                      count: 255,
                    },
                  ),
                },
              },
            )}
            error={!!errors.current_level}
            helperText={errors.current_level?.message}
          />
        </Grid>

        <Grid item xs={6}>
          <InputLabel required>{t('Skills')}</InputLabel>
          <FormControl margin="dense" fullWidth>
            <Controller
              name="skills"
              control={control}
              defaultValue={[]}
              rules={{ required: t('This field is required') as string }}
              render={({ field }) => (
                <Autocomplete
                  multiple
                  id="checkboxes-skill-cv"
                  options={skillsList || []}
                  size="small"
                  filterSelectedOptions
                  limitTags={2}
                  getOptionLabel={option => option.name}
                  renderOption={(props, option) => (
                    <li {...props}>{option.name}</li>
                  )}
                  value={field.value}
                  onChange={(e, newValue) => {
                    field.onChange(newValue);
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      error={!!errors.skills}
                      helperText={errors.skills?.message}
                    />
                  )}
                />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <InputLabel required>{t('Language')}</InputLabel>
          <FormControl margin="dense" fullWidth>
            <Controller
              name="language_proficiency"
              control={control}
              defaultValue={[]}
              rules={{ required: t('This field is required') as string }}
              render={({ field }) => (
                <Autocomplete
                  multiple
                  id="checkboxes-language-cv"
                  options={language_metadata || []}
                  size="small"
                  filterSelectedOptions
                  limitTags={2}
                  getOptionLabel={option => option.name}
                  renderOption={(props, option) => (
                    <li {...props}>{option.name}</li>
                  )}
                  value={field.value}
                  onChange={(e, newValue) => {
                    field.onChange(newValue);
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      error={!!errors.language_proficiency}
                      helperText={errors.language_proficiency?.message}
                    />
                  )}
                />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <InputLabel>{t('Years Of Experience')}</InputLabel>
          <TextFieldRegister
            register={register<keyof RecruitmentCandidateSchema>(
              'years_of_experience',
              {
                maxLength: {
                  value: 50,
                  message: t(
                    'Please enter no more than {{count}} characters.',
                    {
                      count: 50,
                    },
                  ),
                },
              },
            )}
            error={!!errors.exp_years}
            helperText={errors.exp_years?.message}
          />
        </Grid>

        <Grid item xs={3}>
          <InputLabel>{t('Expected Salary')}</InputLabel>
          <TextFieldRegister
            register={register<keyof RecruitmentCandidateSchema>(
              'expected_salary',
              {
                maxLength: {
                  value: 50,
                  message: t(
                    'Please enter no more than {{count}} characters.',
                    {
                      count: 50,
                    },
                  ),
                },
              },
            )}
            error={!!errors.expected_salary}
            helperText={errors.expected_salary?.message}
          />
        </Grid>

        <Grid item xs={3}>
          <InputLabel>{t('Expected Position')}</InputLabel>
          <TextFieldRegister
            register={register<keyof RecruitmentCandidateSchema>(
              'expected_position',
              {
                maxLength: {
                  value: 100,
                  message: t(
                    'Please enter no more than {{count}} characters.',
                    {
                      count: 100,
                    },
                  ),
                },
              },
            )}
            error={!!errors.expected_position}
            helperText={errors.expected_position?.message}
          />
        </Grid>

        <Grid item xs={12}>
          <FormLabel>{t('Work History')}</FormLabel>
          <TextFieldRegister
            register={register<keyof RecruitmentCandidateSchema>(
              'experience_summary',
            )}
            textFieldProps={{ minRows: 5, maxRows: 15, multiline: true }}
          />
        </Grid>

        <Grid item xs={12}>
          <InputLabel required>{t('Upload File')}</InputLabel>
          <FormControl error={'cv_file' in (errors || {})} fullWidth>
            <Controller
              control={control}
              name="cv_file"
              rules={{ required: true }}
              render={() => {
                return (
                  <>
                    <Box
                      {...getRootProps()}
                      border={`1px dashed ${Colors.inputMuiColor}`}
                      borderRadius="4px"
                      mt={1}
                    >
                      <Stack
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        p="40px"
                        sx={{
                          cursor: 'pointer',
                        }}
                      >
                        <Box>
                          <Box width="64px">
                            <CloudUploadIcon
                              sx={{
                                width: '64px',
                                height: '64px',
                                color: Colors.blueText,
                              }}
                            />
                          </Box>
                        </Box>
                        <Typography
                          variant="body1"
                          component="p"
                          fontSize="16px"
                          fontWeight="600"
                        >
                          {t('Drag & drop files or')}{' '}
                          <Typography
                            color="#3699FF"
                            component="span"
                            fontSize="16px"
                            fontWeight="600"
                            sx={{ textDecoration: 'underline' }}
                          >
                            {t('Browse')}
                          </Typography>
                        </Typography>
                        <Typography
                          variant="body1"
                          component="p"
                          fontSize="12px"
                        >
                          {t('Support PDF only')}
                        </Typography>
                      </Stack>
                    </Box>
                    {errors.cv_file && (
                      <FormHelperText sx={{ marginX: '0' }}>
                        {t('This field is required')}
                      </FormHelperText>
                    )}
                  </>
                );
              }}
            />
          </FormControl>

          <FormControl error={'source' in (errors || {})} sx={{ mt: '16px' }}>
            <InputLabel required>{t('CV source')}</InputLabel>
            <Controller
              control={control}
              name={`source`}
              rules={{ required: true }}
              render={({ field }) => {
                const { onBlur, onChange, value } = field;
                return (
                  <>
                    <Select
                      style={{ minWidth: '94px', marginTop: 3 }}
                      autoWidth
                      displayEmpty
                      size="small"
                      value={`${value}`}
                      onBlur={onBlur}
                      label="source"
                      labelId="select-label"
                      id="simple-select"
                      onChange={onChange}
                    >
                      {_.map(sourceCV, v => (
                        <MenuItem key={v.id} value={`${v.id}`}>
                          {v.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.source && (
                      <FormHelperText sx={{ marginX: '0' }}>
                        {t('This field is required')}
                      </FormHelperText>
                    )}
                  </>
                );
              }}
            />
          </FormControl>

          <Box
            pt={!_.isEmpty(watch('cv_file')) ? '16px' : ''}
            overflow="auto"
            display={!_.isEmpty(watch('cv_file')) ? 'block' : 'none'}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              p="10px"
              border="1px solid #3699FF"
              borderRadius="4px"
            >
              <Typography>{getValues('cv_file')?.name}</Typography>

              <Box
                onClick={handleRemoveFile}
                color="red"
                style={{
                  cursor: 'pointer',
                }}
              >
                <DeleteIcon />
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Stack
        direction="row"
        display="flex"
        justifyContent="flex-end"
        p="15px"
        borderTop="1px solid #BDBDBD"
        spacing={2}
      >
        <Button
          variant="contained"
          color="inherit"
          type="button"
          onClick={setCloseModal}
        >
          {t('Cancel')}
        </Button>
        <LoadingButton
          variant="contained"
          color="primary"
          loadingPosition="start"
          startIcon={<SaveIcon />}
          loading={createCandidateStatus === RequestStatus.REQUESTING}
          type="submit"
        >
          {t('Submit')}
        </LoadingButton>
      </Stack>
    </Box>
  );
}
