import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { Container } from 'app/components/Container';
import { Content, Wrapper } from 'app/components/Wrapper';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import styled from 'styled-components';
import { Search as SearchIcon } from '@mui/icons-material';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { selectDepartment } from './slice/selectors';
import { useDepartmentSlice } from './slice';
import { useEffect, useState } from 'react';
import RowChildren from './components/RowChildren';
import RowCell from './components/RowCell';
import { IHierarchyData } from 'types/common';
import { useHistory, useLocation } from 'react-router-dom';
import Path from 'config/clientPath';
import { useGlobalSlice } from '../GlobalContainer/slice';
import { checkIsRequesting } from 'utils/helper';
import { Loader } from 'app/components/Loader';
import DepartmentModalRequest from './components/DepartmentModalRequest';
import { IDepartmentsSchema } from './slice/types';
import { RequestStatus } from 'constants/API';

export function DepartmentPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(window.location.search);
  const [hierarchyData, setHierarchyData] = useState<IHierarchyData[]>([]);
  const [textSearch, setTextSearch] = useState<string>('');
  const {
    treeDepartments,
    getDepartmentStatus,
    getTreeDepartmentStatus,
    createDepartmentStatus,
    updateDepartmentStatus,
    deleteDepartmentStatus,
  } = useSelector(selectDepartment);
  const [departmentDataChange, setDepartmentDataChange] =
    useState<IDepartmentsSchema>();
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [isShowConfirm, setIsShowConfirm] = useState<boolean>(false);
  const {
    actions: {
      getDepartmentRequest,
      getTreeDepartmentRequest,
      deleteDepartmentRequest,
      resetDepartmentRequestStatus,
    },
  } = useDepartmentSlice();
  const { setBreadcumbs, setSuccessMessages, setErrorMessages } =
    useGlobalSlice().actions;

  const handleSearchByName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const textSearch = event?.target?.value;
    const dataSearch = { keyword: textSearch };
    const qs = Object.keys(dataSearch)
      .map(key => (dataSearch[key] ? key + '=' + dataSearch[key] : ''))
      .filter(o => o)
      .join('&');
    history.push(`${Path.DEPARTMENT}?${qs}`);
  };

  const reloadData = () => {
    dispatch(
      getTreeDepartmentRequest({
        keyword: params.get('keyword'),
      }),
    );
    setTextSearch(params.get('keyword') ?? '');
  };

  const handleNotification = (
    status: any,
    textSuccess: string,
    textFail: string,
  ) => {
    if (status === RequestStatus.SUCCESS) {
      reloadData();
      setIsOpenModal(false);
      setDepartmentDataChange(undefined);
      dispatch(getDepartmentRequest({}));
      dispatch(setSuccessMessages([textSuccess]));
    }
    if (status === RequestStatus.ERROR) {
      dispatch(setErrorMessages([textFail]));
    }
  };

  const handleDeleteDepartment = () => {
    setIsShowConfirm(false);
    dispatch(deleteDepartmentRequest({ id: departmentDataChange?.id }));
  };

  useEffect(() => {
    if (treeDepartments) {
      const newDepartments = treeDepartments?.filter(
        department => !department?.parent,
      );
      let hierarchies: IHierarchyData[] = [];
      const buildHierarchy = (deparment: any, parentID: any) => {
        const currentPath = [...parentID, deparment?.id];
        hierarchies.push({
          hierarchy: currentPath,
          isOpen: false,
        });
        deparment?.children?.forEach(child => {
          buildHierarchy(child, currentPath);
        });
      };

      newDepartments.forEach(department => {
        buildHierarchy(department, []);
      });

      const updatedHierarchy = hierarchies?.map(item2 => {
        const matchedItem = hierarchyData?.find(
          item1 =>
            JSON.stringify(item1.hierarchy) === JSON.stringify(item2.hierarchy),
        );

        return matchedItem
          ? { ...item2, isOpen: matchedItem.isOpen }
          : { ...item2, isOpen: false };
      });
      setHierarchyData(updatedHierarchy);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treeDepartments]);

  useEffect(() => {
    dispatch(setBreadcumbs([{ title: t('Departments') }]));
    reloadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  useEffect(() => {
    dispatch(getDepartmentRequest({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleNotification(
      updateDepartmentStatus,
      t('Update department successful!'),
      t('Update department not successful!'),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateDepartmentStatus]);

  useEffect(() => {
    handleNotification(
      createDepartmentStatus,
      t('Create department successful!'),
      t('Create department not successful!'),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createDepartmentStatus]);

  useEffect(() => {
    handleNotification(
      deleteDepartmentStatus,
      t('Delete department successful!'),
      t('Delete department not successful!'),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteDepartmentStatus]);

  useEffect(
    () => () => {
      dispatch(resetDepartmentRequestStatus());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ['componentWillUnMount'],
  );

  const isLoading = checkIsRequesting([
    getDepartmentStatus,
    getTreeDepartmentStatus,
    deleteDepartmentStatus,
    createDepartmentStatus,
    updateDepartmentStatus,
  ]);

  return (
    <>
      <Helmet>
        <title>{t('Department list')}</title>
        <meta name="description" content={t('Department list')} />
      </Helmet>
      <Container>
        <Wrapper>
          <Loader open={isLoading} />
          <Content spacing={2}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Typography variant="h6" component="h6">
                {t('Department list')}
              </Typography>
            </Box>
            <Box display="flex" flexDirection="row">
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                marginLeft="auto"
              >
                <Stack direction="row" spacing={2} alignItems="center">
                  <Button
                    variant="contained"
                    startIcon={<AddIcon />}
                    onClick={() => {
                      setIsOpenModal(true);
                    }}
                  >
                    {t('Add new')}
                  </Button>
                </Stack>
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  marginLeft={2}
                >
                  <TextField
                    type="search"
                    placeholder={t('Search')}
                    aria-label="Search"
                    size="small"
                    sx={{
                      background: '#fff',
                    }}
                    value={textSearch}
                    onChange={e => setTextSearch(e?.target?.value)}
                    onKeyUp={(event: any) => {
                      if (event.key === 'Enter') {
                        handleSearchByName(event);
                      }
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Stack>
              </Stack>
            </Box>
            <WrapTable>
              <TableContainer component={Paper}>
                <Table stickyHeader aria-label="collapsible table">
                  <TableHead>
                    <TableRow sx={{ backgroundColor: '#f3f6f9' }}>
                      <TableCell
                        sx={{ maxWidth: 60, background: 'silver' }}
                        width={60}
                      ></TableCell>
                      <TableCell
                        sx={{ maxWidth: 400, background: 'silver' }}
                        width={`fill`}
                      >
                        {t('Department name')}
                      </TableCell>
                      <TableCell
                        sx={{
                          maxWidth: 100,
                          background: 'silver',
                          textAlign: 'center',
                        }}
                        width={100}
                      >
                        {t('Members')}
                      </TableCell>
                      <TableCell
                        sx={{
                          maxWidth: 120,
                          textAlign: 'center',
                          background: 'silver',
                        }}
                        width={120}
                      >
                        {t('Action')}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {_.isEmpty(treeDepartments) ? (
                      <TableRow>
                        <TableCell colSpan={4} sx={{ textAlign: 'center' }}>
                          {t('No data')}
                        </TableCell>
                      </TableRow>
                    ) : (
                      <>
                        {treeDepartments?.map((deparment: any, ind: any) => {
                          const cssPaddingLeft = 10;
                          return (
                            <>
                              {!deparment?.parent && (
                                <>
                                  <RowCell
                                    childs={deparment}
                                    isParent={true}
                                    parentId={deparment?.id}
                                    cssPaddingLeft={0}
                                    hierarchyData={hierarchyData}
                                    setHierarchyData={setHierarchyData}
                                    setDepartmentDataChange={
                                      setDepartmentDataChange
                                    }
                                    setIsOpenModal={setIsOpenModal}
                                    setIsShowConfirm={setIsShowConfirm}
                                  />
                                  {deparment?.children?.length > 0 && (
                                    <RowChildren
                                      childs={deparment?.children}
                                      parentId={deparment?.id}
                                      cssPaddingLeft={cssPaddingLeft}
                                      hierarchyData={hierarchyData}
                                      setHierarchyData={setHierarchyData}
                                      setDepartmentDataChange={
                                        setDepartmentDataChange
                                      }
                                      setIsOpenModal={setIsOpenModal}
                                      setIsShowConfirm={setIsShowConfirm}
                                    />
                                  )}
                                </>
                              )}
                            </>
                          );
                        })}
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </WrapTable>
          </Content>
          <DepartmentModalRequest
            isOpenModal={isOpenModal}
            setIsOpenModal={setIsOpenModal}
            departmentDataChange={departmentDataChange}
            setDepartmentDataChange={setDepartmentDataChange}
          />
          <Dialog
            open={isShowConfirm}
            onClose={() => {
              setIsShowConfirm(false);
            }}
          >
            <DialogTitle>{t('Delete department info')}</DialogTitle>

            <DialogContent>
              <DialogContentText>
                {t('Do you want to delete this department bellow')} ?
              </DialogContentText>
              <Typography variant="subtitle1" component="div">
                {t('Name')}: {departmentDataChange?.name}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setIsShowConfirm(false)}>
                {t('Close')}
              </Button>
              <Button color="error" onClick={() => handleDeleteDepartment()}>
                {t('Delete')}
              </Button>
            </DialogActions>
          </Dialog>
        </Wrapper>
      </Container>
    </>
  );
}

const WrapTable = styled(Box)`
  & .MuiTableContainer-root {
    max-height: calc(100vh - 230px);
  }

  & .MuiAvatarGroup-avatar {
    background-color: #ffd8bf;
    color: #fa541c;
  }

  & .MuiAvatar-root:last-child {
    margin-left: -8px !important;
  }

  .cursor_pointer {
    cursor: pointer;
  }
`;
