import React, { useEffect } from 'react';

// UI
import { Helmet } from 'react-helmet-async';

import {
  Box,
  Button,
  Typography,
  Stack,
  Pagination,
  TextField,
  InputAdornment,
  InputLabel,
  ToggleButtonGroup,
  ToggleButton,
} from '@mui/material';

import { Container } from 'app/components/Container';
import { Wrapper, Content } from 'app/components/Wrapper';
import { Search as SearchIcon } from '@mui/icons-material';

// Redux & Sagas
import { useDispatch, useSelector } from 'react-redux';

// Library
import _ from 'lodash';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useTranslation } from 'react-i18next';
import { useMemberSlice } from './slice';
import { selectMember } from './slice/selectors';
import { checkIsRequesting, handleParamSearch } from 'utils/helper';
import { Loader } from 'app/components/Loader';
import queryString from 'query-string';
import Path from 'config/clientPath';
import { useHistory, useLocation } from 'react-router-dom';
import { useGlobalSlice } from '../GlobalContainer/slice';
import { ViewMemberModeTable } from './components/ViewMemberModeTable';
import { FilterModal } from './components/FilterModal';
import { ReactComponent as GridViewIcon } from 'assets/icons/viewmode/grid.svg';
import { ReactComponent as ViewModeListIcon } from 'assets/icons/project/table.svg';
import { ViewMemberModeGrid } from './components/ViewMemberModeGrid';

export function MemberPage() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(window.location.search);
  const [isOpenFilterModal, setIsOpenFilterModal] =
    React.useState<boolean>(false);
  const [txtSearch, setTxtSearch] = React.useState<string>('');
  const [currentMemberTab, setCurrentMemberTab] = React.useState<string | null>(
    'GRID_TAB',
  );
  const {
    listMember,
    getMemberListStatus,
    getMemberRoleListStatus,
    getDepartmentStatus,
    getTechnicalSkillStatus,
  } = useSelector(selectMember);
  const {
    actions: {
      getMemberListRequest,
      getMemberRoleListRequest,
      getDepartmentRequest,
      getTechnicalSkillRequest,
      resetMemberInfoRequestStatus,
    },
  } = useMemberSlice();
  const { setBreadcumbs } = useGlobalSlice().actions;

  const defaultParams = {
    roles: params.get('roles'),
    status: params.get('status'),
    type__in: params.get('type__in'),
    departments: params.get('departments'),
    skills: params.get('skills'),
    tab: params.get('tab'),
  };

  const countFilter = () => {
    const countFields = [
      'roles',
      'status',
      'type__in',
      'departments',
      'skills',
    ];
    const count = countFields.filter(key => params.get(key)).length;
    if (count > 0) {
      return '+' + count;
    }
    return '';
  };

  const reloadData = () => {
    setCurrentMemberTab(params?.get('tab') ?? 'GRID_TAB');
    dispatch(
      getMemberListRequest({
        ...defaultParams,
        page: Math.abs(_.toInteger(params.get('page')) || 1),
        search: params.get('search') ?? '',
        paging: true,
      }),
    );
    setTxtSearch(params.get('search') ?? '');
  };

  const getParamsSearch = (
    page: any,
    isChangePage: boolean,
    currentMemberTab?: string,
  ) => {
    let paramsPage = queryString.parse(window.location.search);
    const paramChangePage = {
      status: paramsPage?.status ? Number(paramsPage?.status) : '',
      type__in: paramsPage?.type__in,
      roles:
        paramsPage?.roles === ''
          ? paramsPage?.roles
          : paramsPage?.roles?.split(',').map(Number),
      departments:
        paramsPage?.departments === ''
          ? paramsPage?.departments
          : paramsPage?.departments?.split(',').map(Number),
      skills:
        paramsPage?.skills === ''
          ? paramsPage?.skills
          : paramsPage?.skills?.split(',').map(Number),
      search: paramsPage?.search ?? '',
      page: page,
      tab: isChangePage ? paramsPage?.tab : currentMemberTab,
    };
    return paramChangePage;
  };

  const handleSearchByName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const textSearch = event?.target?.value;
    const dataSearch = {
      ...defaultParams,
      page: 1,
      search: textSearch,
    };
    history.push(`${Path.MEMBERS}?${handleParamSearch(dataSearch)}`);
  };

  const handleChangePage = (
    event: React.ChangeEvent<unknown> | null,
    newPage: number,
  ) => {
    const paramChangePage = getParamsSearch(+newPage, true);
    history.push(`${Path.MEMBERS}?${handleParamSearch(paramChangePage)}`);
  };

  const handleChangeMemberTab = (event: any, currentMemberTab) => {
    let params = queryString.parse(window.location.search);
    const paramChangePage = getParamsSearch(
      params?.page,
      false,
      currentMemberTab,
    );
    setCurrentMemberTab(currentMemberTab);
    history.push(`${Path.MEMBERS}?${handleParamSearch(paramChangePage)}`);
  };

  useEffect(() => {
    reloadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  useEffect(() => {
    dispatch(setBreadcumbs([{ title: 'Member management' }]));
    dispatch(getMemberRoleListRequest({}));
    dispatch(getDepartmentRequest({}));
    dispatch(getTechnicalSkillRequest({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () => () => {
      dispatch(resetMemberInfoRequestStatus());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ['componentWillUnMount'],
  );

  const isLoading = checkIsRequesting([
    getMemberListStatus,
    getMemberRoleListStatus,
    getDepartmentStatus,
    getTechnicalSkillStatus,
  ]);

  return (
    <>
      <Helmet>
        <title>{t('Member management')}</title>
        <meta name="description" content={t('Member management')} />
      </Helmet>
      <Container>
        <Wrapper>
          <Loader open={isLoading} />
          <Content spacing={2}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Typography variant="h6" component="h6">
                {t('Member management')}
              </Typography>
              <Button
                variant="contained"
                startIcon={<AddCircleIcon />}
                onClick={() => {
                  history.push(`${Path.ADD_MEMBER}`);
                }}
              >
                {t('Add new Member')}
              </Button>
            </Box>
            {isOpenFilterModal && (
              <FilterModal
                isOpenFilterModal={isOpenFilterModal}
                setIsOpenFilterModal={setIsOpenFilterModal}
              />
            )}

            {_.size(listMember) > 0 && (
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="end"
                  alignItems="center"
                >
                  <Button
                    variant="contained"
                    startIcon={<FilterAltIcon />}
                    color="inherit"
                    style={{ marginRight: '6px' }}
                    onClick={() => {
                      setIsOpenFilterModal(true);
                    }}
                  >
                    {t('Filter member')}
                  </Button>
                  {countFilter()}
                </Box>
                <Box display="flex" flexDirection="row" justifyContent="end">
                  <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    sx={{ marginRight: '10px' }}
                  >
                    <InputLabel>{t('View mode')}:</InputLabel>
                    <ToggleButtonGroup
                      value={currentMemberTab}
                      exclusive
                      onChange={(event, newValue) => {
                        if (
                          newValue !== null &&
                          newValue !== currentMemberTab
                        ) {
                          handleChangeMemberTab(event, newValue);
                        }
                      }}
                    >
                      <ToggleButton value="GRID_TAB" aria-label="account">
                        <GridViewIcon />
                      </ToggleButton>
                      <ToggleButton value="LIST_TAB" aria-label="table">
                        <ViewModeListIcon />
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Stack>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <TextField
                      type="search"
                      placeholder="Name"
                      aria-label="Search"
                      size="small"
                      sx={{
                        background: '#fff',
                      }}
                      value={txtSearch}
                      onChange={e => setTxtSearch(e?.target?.value)}
                      onKeyUp={(event: any) => {
                        if (event.key === 'Enter') {
                          handleSearchByName(event);
                        }
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Stack>
                </Box>
              </Box>
            )}

            {currentMemberTab === 'GRID_TAB' ? (
              <>
                <ViewMemberModeGrid />
              </>
            ) : (
              <>
                <ViewMemberModeTable />
              </>
            )}
            {_.size(listMember?.data) > 0 && (
              <Stack spacing={2} alignItems={'flex-end'}>
                <Pagination
                  page={listMember?.current_page || 1}
                  color="primary"
                  count={listMember?.total_page || 0}
                  variant="outlined"
                  shape="rounded"
                  onChange={handleChangePage}
                />
              </Stack>
            )}
          </Content>
        </Wrapper>
      </Container>
    </>
  );
}
